<template>
  <div>
    <!--  -->
    <div class="primary-bg-box">
      <section class="container comercios-adheridos">
        <div class="row justify-content-center">
          <div class="col-12 col-md-4">
            <div class="comercios-adheridos-box">
              <figure><img src="/img/comercios-adheridos-icon.svg" alt="Comercios adheridos"></figure>
              <h2>Comercios adheridos.</h2>
              <form action="" name="comercios-adheridos">
                <div class="form-row">
                  <select class="" name="localidadesSelect" v-model="localidad"  v-if="this.localidades.success">
                    <option value="all">Localidad</option>
                    <option :value="localidad.localidad" v-for="localidad in localidades.data">{{localidad.localidad}}</option>
                  </select>
                </div>
                <div class="form-row">
                  <select class="" name="localidadesSelect" v-model="rubro" v-if="this.rubros.success">
                    <option value="all">Rubro</option>
                    <option :value="rubro.rubro" v-for="rubro in rubros.data">{{rubro.rubro}}</option>
                  </select>
                </div>
                    <!-- <router-link to="/comercios/">comercios</router-link> -->
                  <button type="submit" class="btn-primary-invert block" name="enviar-contacto" @click.prevent="sendCoemrciosFilter()">Buscar</button>

              </form>
              <!-- <p class="comercios-adheridos-next">Próximamente podrá encontrar los comercios más cercanos a su ubicación.</p> -->
            </div>
          </div>
        </div>
      </section>
    </div>
    <!--  -->
  </div>
</template>

<script>
import Comercio from './../Classes/ComerciosClass.js';
import msg from './../components/msg';

export default {
  data(){
    return{
      localidades : new Comercio(),
      rubros: new Comercio(),
      localidad: 'all',
      rubro: 'all',
    }
  },
  created(){
    this.localidades.getLocalidades();
    this.rubros.getRubros();
  },
  mounted(){

  },
  computed:{

  },
  methods:{
    sendCoemrciosFilter(){
      ///comercios/:localidad/:rubro
      let localidad = this.localidad;
      let rubro = this.rubro;
      this.$store.commit('setLocalidad',localidad);
      this.$store.commit('setRubro',rubro);
      this.$router.push('/comercios');
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
