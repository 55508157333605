export default class BannerClass{

  constructor(){
    this.data = {};
    this.success = false;
  }

  list(){
    this._record('get','/banner/list',{});
  }
  show(id){
  this._record('get','/banner/show/'+id,{});
  }
  update(id,data){
    this._record('post','/banner/update/'+id,data);
  }
  destroy(id){
    this._record('delete','/banner/'+id,{});
  }
  store(data){
    this._record('post','/banner/insert/',data);
  }




  _record(method,url,givenData){
    axios[method](url,givenData)
    .then(response =>{this._onSuccess(response)})
    .catch(error =>{this._onError(error)});
  }

  _onSuccess(response){
    this.data = response.data;
    return this.success = true;
  }
  _onError(error){
    this.data = {error: true, errorMsg: error }
  }

}
