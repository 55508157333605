import Croppie from 'croppie';

export default class Crop{
  constructor(width, height,vw,vh, type, format){
      this.errorMsg = ''
      this.success = false;
      this.image = '';
      this.imageUrl = '';
      this.cropIsShow = false;
      this.cropObject = null;
      this.cropContainer = null;
      this.width = width;
      this.height = height;
      this.vw = vw;
      this.vh = vh;
      this.type = type;
      this.format = format;
  }

  cropImage(fieldName, file, cropElement){
    this.cropContainer = cropElement;
    const { maxSize } = this;
    let imageFile = file[0];
    if (file.length>0) {

      let size = imageFile.size / maxSize / maxSize;

      if (!imageFile.type.match('image.*')) {
        this.errorMsg = 'No es un formato de imagen';
      }else if (size > 1){
        this.errorMsg ='La imagen es muy grande';
      }else{
      //  this.image = imageFile;
        let imageURL = URL.createObjectURL(imageFile);
        this.imageUrl = imageURL;
        this._setCrop(this.cropContainer);
      }
    }
  }


  _setCrop(container){
    var cropImage = new Croppie(container,{
      viewport: {
        width: this.vw,
         height: this.vh,
         type: this.type
       },
      boundary: { width: this.vw, height: this.vh },
      showZoomer: true,
      enableOrientation: false,
      enforceBoundary: false
    });

    cropImage.bind({
      url: this.imageUrl,
      orientation: 4
    });

      this.cropObject = cropImage;
  }

  _resultImageFormat(srcImage){

  }

  cropResult(){
    let cropResult = this.cropObject;
    var vm = this;
    let imageWidth = vm.width;
    let imageheight = vm.height;

    let options = {
      type: 'base64',
      size: { width: imageWidth, height: imageheight },
      format: this.format
    }

    cropResult.result(options).then(function(result) {
      vm.image = result;
    });
    cropResult.destroy();
  }



  //--------------------
}
