export default class Comments{

  constructor(){
    this.data = {};
  }

  list(id){
    this._record('get','/comentario/showAllByUser/'+id,{});
  }
  count(id){
    this._record('get','/comentario/showCountByUser/',{});
  }

  show(id){

  }
  update(id){
    this._record('get','/'+id,{});
  }
  delete(id){

  }
  create(data){
    this._record('post','/comentario/insert',data);
  }


  _record(method,url,givenData){
    axios[method](url,givenData)
    .then(response =>{this._onSuccess(response)})
    .catch(error =>{this._onError(error)});
  }

  _onSuccess(response){
    this.data = response.data;
  }
  _onError(error){
    this.data = {error: true, errorMsg: error }
  }

}
