import VueRouter from 'vue-router';

let routes = [

  {
    path:'/contactos/',
    component: require('./views/contactosList').default
  },
  {
    path:'/contactoDetalle/:id',
    component: require('./views/contactoDetalle').default
    //props: true
  },
  {
    path:'/perfil/',
    component: require('./views/perfil').default
    //props: true
  },
  {
    path:'/banner/',
    component: require('./views/banner').default
    //props: true
  },
  {
    path:'/slide/',
    component: require('./views/slide/slide').default
    //props: true
  },
  {
    path:'/slideadd/',
    component: require('./views/slide/slideAdd').default
    //props: true
  },
  {
    path:'/comerciosImport/',
    component: require('./views/comercios/comerciosImport').default
    //props: true
  },
  {
    path:'/sucursales/',
    component: require('./views/sucursales/sucursales').default
    //props: true
  },
  {
    path:'/sucursales/nueva/',
    component: require('./views/sucursales/sucursalesAdd').default
    //props: true
  },
  {
    path:'/sucursales/edit/:id',
    component: require('./views/sucursales/sucursalesEdit').default,
    props: true
  },
  {
    path:'/sucursales/order/',
    component: require('./views/sucursales/sucursalesOrder').default,
    props: true
  },
  {
    path:'/asesores/listado/',
    component: require('./views/asesoresList').default
    //props: true
  },
  {
    path:'/sucursales/listado/',
    component: require('./siteViews/sucursalesListado').default

    //props: true
  },
  {
    path:'/',
    component: require('./siteViews/home').default,
    children: [
      {
      name:'simulador',
      path:'',
      component: require('./siteViews/simulador/simulacion').default,
    },
      {
      name:'datosPersonales',
      path:'/datos-personales',
      component: require('./siteViews/simulador/datosPersonales').default,
    }
  ]
    //props: true
  },
  {
    path:'/comercios',
    component: require('./siteViews/comerciosAdheridos').default,
    props: true
  },




]

export default new VueRouter({
    routes
});
