<template>
  <div>
    <!--  -->
    <div class="row">
      <div class="col-12 col-md-6">
        <h1>Sucursales</h1>
      </div>
      <div class="col-12 col-md-6">
        <router-link to="/sucursales/nueva" class="btn-rose">Agregar sucursal</router-link>
        <router-link to="/sucursales/order" class="btn-rose">Ordenar sucursales</router-link>
      </div>

    </div>
    <div class="container">
      <div class="row justify-content-left">
        <div class="col-12 col-md-6">
          <div class="adm-sucursales-box">
            <vuescroll :ops="ops">
            <div class="adm-sucursales-inner">

              <div class="adm-sucursales-item" v-for="(sucursal, index) in sucursales.data"  :key="sucursales.data.id">
                <h2>{{sucursal.name}}</h2>
                <p class="address"><i class="material-icons">place</i> {{sucursal.address}}</p>
                <p class="transports"><i class="material-icons">directions_bus</i> {{ getTransportsInFormat(sucursal.transports) }}</p>
                <div class="card-footer">
                  <button type="button" class="btn-erase" name="button" @click.prevent="showEraseAlert(sucursal.id)"><i class="material-icons">delete</i></button>
                  <!-- <button type="button" class="btn-edit" name="button"><i class="material-icons">edit</i></button> -->
                  <router-link class="btn-edit" :to="'/sucursales/edit/'+sucursal.id"><i class="material-icons">edit</i></router-link>
                </div>
              </div>

            </div>
            </vuescroll>
          </div>
        </div>
       </div>
    </div>
    <!--  -->
  </div>

</template>

<script>
import Sucursales from './../../Classes/sucursalesClass';
import vuescroll from 'vuescroll';

export default {
  components:{
    vuescroll
  },
  data(){
    return{

      center: { lat: -34.6412584, lng: -58.6268535},
      zoom: 10,
      sucursales: new Sucursales(),
      places: [],
      currentPlace: null,
      markers:[],
      infoContent: {
        name: '',
        address: '',
      },
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,
      //optional: offset infowindow so it visually sits nicely on top of our marker
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35
        }
      },
      //scrollPanel
      ops: {
         vuescroll: {
           mode: 'native',
           sizeStrategy: 'percent',
           detectResize: false
         },
         scrollPanel: {
           initialScrollY: 0,
           initialScrollX: false,
           scrollingX: false,
           scrollingY: true,
           speed: 300,
           easing: 'easeOutQuad',
           verticalNativeBarPos: 'right'
         },
         rail: {
           background: '#808080',
           opacity: 0.2,
           size: '6px',
           specifyBorderRadius: false,
           gutterOfEnds: null,
           gutterOfSide: '2px',
           keepShow: true
         },
         bar: {
           showDelay: 100,
           onlyShowBarOnScroll: false,
           keepShow: true,
           background: '#cecece',
           opacity: 1,
           hoverStyle: false,
           specifyBorderRadius: false,
           minSize: 0,
           size: '6px',
           disable: false
         }
       },

    }
  },
  mounted() {
   this.sucursales.list();
   this.geolocate();
 },
 computed:{
 },
  methods: {
    showEraseAlert(id){
      var tmpId = id;
      this.$swal({
        title: 'Vas a borrar una sucursal',
        text: "después no podras recuperarla",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#D64F4F',
        cancelButtonColor: '#21ACD8',
        confirmButtonText: 'Borrarla',
        cancelButtonText: 'NO!',
      }).then((result) => {

        if (result.value) {
          this.borrarSucursal(tmpId)
          this.$swal(
            'La borramos!',
            'La sucursal fue eliminada',
            'success'
          )
        }
      })
    },
    // receives a place object via the autocomplete component
    setPlace(place) {
      this.currentPlace = place;
    },

    geolocate: function() {
      navigator.geolocation.getCurrentPosition(position => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
      });
    },
    getCoordsFromSucursales(lat,lng){
      let position = {
        lat: _.toNumber(lat),
        lng: _.toNumber(lng)
      }
      return position;
    },
    toggleInfoWindow: function(marker, idx) {
      this.infoWindowPos = this.getCoordsFromSucursales(marker.lat,marker.lng)
      this.infoContent.name = marker.name;
      this.infoContent.address = marker.address;
      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
        this.centerSucursal(marker.lat,marker.lng);
      }
    },
    centerSucursal(lat,lng,zoom){
      this.center = this.getCoordsFromSucursales(lat,lng);
      if (zoom) {
        this.zoom = zoom;
      }
    },
    getTransportsInFormat(transports){
      return _.join(_.split(transports,','),' / ');
    },
    borrarSucursal(id){
      this.sucursales.destroy(id);
    }
  }
}
</script>

<style lang="css" scoped>
.sucursale-map, .adm-sucursales-inner{
  height: 640px;
  padding-top: 0;
}
.infotooltipName{
  font-weight: bold;
}
</style>
