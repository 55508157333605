<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-8">
        <h1>Slider de promociones</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-8">
      <router-link class="btn-rose" to="/slideadd">Nuevo</router-link>
      </div>
    </div>
    <br>
    <br>
    <br>
    <br>
    <div class="row">
      <div class="col-12 col-md-6">

        <draggable v-show="!this.slide.complete || this.slide.loading" v-model="slides" :options="{animation:200, handle:'.list-handle'}" @end="orderMove()">
          <div v-for="(slide, index) in slides" :key="slides.id">
            <div class="card-contacto slide-admin-card">
              <div class="slide-order-icon-box">
                <figure class="list-handle">
                  <i class="material-icons">unfold_more</i>
                </figure>

                <figure class="slide-order-box">
                  {{ slide.order = slides.indexOf(slide)}}
                </figure>
              </div>

              <div class="card-body">
                <div class="row">
                  <div class="col-6">
                    <figure class="slide-image-preview">
                      <img :src="slide.image" :alt="slide.name">
                    </figure>
                  </div>
                  <div class="col-6">
                    <ul class="slide-list-data">
                      <li>
                        <label>Nombre</label>
                        <span>{{slide.name}}</span>
                      </li>
                      <li>
                        <label>Link</label>
                        <span>{{slide.url}}</span>
                      </li>
                    </ul>

                  </div>
                </div>

              </div>

              <div class="card-footer">
                <button type="button" name="button" @click.prevent="showEraseAlert(slide.id)" ><i class="material-icons">delete</i></button>
                <!-- <button type="button" name="button" ><i class="material-icons">edit</i></button> -->
              </div>
            </div>
          </div>
        </draggable>
      </div>

      <div class="col-12 col-md-4">
        <div class="slide-guardar-orden-cta">
          <a v-if="!slide.complete" href="" class="btn-rose" @click.prevent="updateOrder()">Guardar orden</a>
          <div class="loading-msg" v-if="slide.loading == true">
            <div class="loading-inner">
              <figure class="loading-icon">
                <div class="lds-hourglass"></div>
              </figure>
              <p>Estamos pensando</p>
            </div>
          </div>
        </div>
      </div>


    </div>

    <div class="loading-msg" v-if="slide.complete == true">
      <div class="loading-inner">
        <figure class="success-icon">
          <div class="success-checkmark">
            <div class="check-icon">
              <span class="icon-line line-tip"></span>
              <span class="icon-line line-long"></span>
              <div class="icon-circle"></div>
              <div class="icon-fix"></div>
            </div>
          </div>
        </figure>
        <p>Procesamos con éxito!</p>
        <div class="loading-msg-cta">
          <button @click.prevent="$router.go();" class="btn-rose">Listo!</button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import Slide from './../../Classes/SlideClass';
import draggable from 'vuedraggable'

export default {
  components : {
    draggable
  },
  data(){
    return{
      slide : new Slide(),
      items: []
    }
  },
  mounted(){
    this.slide.list();

  },
  watch:{
    'slide.data':function(val){
      if (val) {
        this.$store.commit('updateSlides',  this.slide.data)
      }
    }
  },
  computed:{
    orderedBanners: function () {
      return _.orderBy(this.slide.data, 'order', 'desc');
    },
    slides: {
       get() {
         return this.$store.state.slides;
       },
       set(value) {
           this.$store.commit('updateSlides', value)
       }
   }
  },
  methods:{
    showEraseAlert(id){
      var tmpId = id;
      this.$swal({
        title: 'Vas a borrar un slide',
        text: "después no podras recuperarlo",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#D64F4F',
        cancelButtonColor: '#21ACD8',
        confirmButtonText: 'Borrarlo',
        cancelButtonText: 'NO!',
      }).then((result) => {

        if (result.value) {
          this.borrarSucursal(tmpId)
          this.$swal(
            'Lo borramos!',
            'El slide fue eliminado',
            'success'
          )
        }
      })
    },
    borrarSucursal(id){
      this.slide.destroy(id);
    },
    orderMove(){

    },
    updateOrder(){
      this.slide.saveOrder(this.slide.dataArray);
    }
  }

}
</script>
