<template>
  <!--  -->
  <div>
    <section class="requisitos">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="req-panel">

              <div class="req-panel-item">
                <a href="#" @click.prevent="setDisplayPanel(1)" class="req-header">
                  <h3>Requisitos para sacar un préstamo</h3>
                  <figure>
                    <i class="material-icons">keyboard_arrow_down</i>
                  </figure>
                </a>
                  <div class="req-body" id="panel1" ref="panel1" :class="[{open: this.show === 1},{close: this.show !== 1}]">
                  <p>Te pedimos que cumplas con unos pocos requisitos para acceder a tu préstamo:</p>
                  <ul>
                    <li>
                      <i class="material-icons">check_circle_outline</i>
                      <p>Ser Argentino o Extranjero con DNI.</p>
                    </li>
                    <li>
                      <i class="material-icons">check_circle_outline</i>
                      <p>Ser mayor de 18 años y menor a 75.</p>
                    </li>
                    <li>
                      <i class="material-icons">check_circle_outline</i>
                      <p>Residir en la República Argentina. El domicilio particular que declares será validado.</p>
                    </li>
                    <li>
                      <i class="material-icons">check_circle_outline</i>
                      <p>Tener una dirección de e-mail propia.</p>
                    </li>
                    <li>
                      <i class="material-icons">check_circle_outline</i>
                      <p>Tener o haber tenido buen comportamiento crediticio en tarjetas de crédito, préstamos personales, prendarios, créditos hipotecarios u otros productos bancarios. <strong>Presta Cash</strong> consultará tu historia crediticia en las centrales de riesgos más conocidas.</p>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="req-panel-item">
                <a href="#" @click.prevent="setDisplayPanel(2)" class="req-header">
                  <h3>Características del préstamo</h3>
                  <figure>
                    <i class="material-icons">keyboard_arrow_down</i>
                  </figure>
                </a>
                <div class="req-body" :class="[{open: this.show === 2},{close: this.show !== 2}]">
                  <p>Te ofrecemos el préstamo que más te conviene:</p>
                  <ul>
                    <li>
                      <i class="material-icons">check_circle_outline</i>
                      <p>Monto máximo para clientes $60.000 y para nuevos clientes $30.000.</p>
                    </li>
                    <li>
                      <i class="material-icons">check_circle_outline</i>
                      <p>Plazo de 1 a 12 meses.</p>
                    </li>
                    <li>
                      <i class="material-icons">check_circle_outline</i>
                      <p>En pesos, a tasa fija.</p>
                    </li>
                    <li>
                      <i class="material-icons">check_circle_outline</i>
                      <p>La pre cancelación total o parcial del préstamo es sin costo.</p>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="req-panel-item">
                <a href="#" class="req-header" @click.prevent="setDisplayPanel(3)">
                  <h3>Cómo solicitar un préstamo</h3>
                  <figure>
                    <i class="material-icons">keyboard_arrow_down</i>
                  </figure>
                </a>

                <div class="req-body" :class="{open: this.show === 3}">
                  <p>Solicitar un Préstamo en Presta Cash es muy fácil y cómodo:</p>
                  <ul>
                    <li>
                      <i class="material-icons">check_circle_outline</i>
                      <p>Si deseas tomar un préstamo por primera vez con Presta Cash, tenés que completar
                      la solicitud de préstamo con todos tus datos. Accede a la solicitud de préstamo
                      ingresando <a href="#" @click.prevent="scrolltoPin('.contact-form-header')" target="_blank">aquí</a>.</p>
                    </li>
                    <li>
                      <i class="material-icons">check_circle_outline</i>
                      <p>Una vez procesada nuestros agentes de ventas se comunicaran vía telefónica.</p>
                    </li>
                  </ul>
                  <p>Los préstamos Presta Cash son de libre destino y podés usarlos para lo que vos quieras:</p>
                  <ul>
                    <li>
                      <i class="material-icons">done_all</i>
                      <p>Refacción o ampliación de Hogares o comercios.</p>
                    </li>
                    <li>
                      <i class="material-icons">done_all</i>
                      <p>Compra de Auto.</p>
                    </li>
                    <li>
                      <i class="material-icons">done_all</i>
                      <p>Cancelación de Deuda.</p>
                    </li>
                    <li>
                      <i class="material-icons">done_all</i>
                      <p>Turismo.</p>
                    </li>
                    <li>
                      <i class="material-icons">done_all</i>
                      <p>Compras Varias.</p>
                    </li>
                    <li>
                      <i class="material-icons">done_all</i>
                      <p>Gastos Imprevistos.</p>
                    </li>
                    <li>
                      <i class="material-icons">done_all</i>
                      <p>Fiestas, Casamientos, Cumpleaños de 15, otros.</p>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="req-panel-item">
                <a href="#" class="req-header" @click.prevent="setDisplayPanel(4)">
                  <h3>Documentación necesaria</h3>
                  <figure>
                    <i class="material-icons">keyboard_arrow_down</i>
                  </figure>
                </a>
                <div class="req-body" :class="{open: this.show === 4}">
                  <p>Solicitamos documentación respaldatoria solo si el préstamo es aprobado:</p>
                  <ul>
                    <li>
                      <i class="material-icons">check_circle_outline</i>
                      <p>Una vez que aprobemos tu solicitud de Préstamo, te vamos a pedir que te acerques a una sucursal mas cercana con
                        la documentación respaldatoria para certificar tu identidad, domicilio e ingresos.</p>
                      </li>
                  </ul>
                  <p>
                    <span>
                    El documento de identidad tiene que ser el que declaraste en la solicitud,
                    el servicio facturado tiene que tener el mismo domicilio que declaraste y
                    para certificar tus ingresos últimos recibos de sueldo si trabajas en relación de dependencia.
                    </span>
                  </p>
                </div>
              </div>

              <div class="req-panel-item">
                <a href="#" class="req-header" @click.prevent="setDisplayPanel(5)">
                  <h3>Pago de las cuotas</h3>
                  <figure>
                    <i class="material-icons">keyboard_arrow_down</i>
                  </figure>
                </a>
                <div class="req-body" :class="{open: this.show === 5}">
                  <p>Solicitamos documentación respaldatoria solo si el préstamo es aprobado:</p>
                  <ul>
                    <li>Las cuotas están compuestas por: Capital amortizado, intereses, e impuestos (IVA).</li>
                    <li>Las cuotas se abonan en las oficinas comerciales de Presta Cash a través de pago en efectivo, pago con tarjeta de debito (visa y maestro) o mercado pago
                    Trasferencia bancaria.</li>
                    <li>Recuerde que en el caso de incumplimiento de la cuota; posterior a la fecha de vencimiento este importe es trasladado al sistema de morosidad, generándose una penalidad por atraso de Pago.</li>
                    <li>La penalidad por atraso de pago se cobra al 1er día posterior de la fecha de vencido, esta misma varía acorde al plan solicitado.</li>
                  </ul>
                </div>
              </div>

              <div class="req-panel-item">
                <a href="#" class="req-header" @click.prevent="setDisplayPanel(6)">
                  <h3>Ventajas de elegir Presta Cash</h3>
                  <figure>
                    <i class="material-icons">keyboard_arrow_down</i>
                  </figure>
                </a>
                <div class="req-body" :class="{open: this.show === 6}">
                  <p>En Presta Cash trabajamos para ser la mejor opción del mercado en Préstamos Personales:</p>
                  <ul>
                    <li>Tenemos más de 16 años de experiencia otorgando préstamos personales y sabemos cómo hacerlo.</li>
                  </ul>
                </div>
              </div>

              <div class="req-panel-item">
                <a href="#" class="req-header" @click.prevent="setDisplayPanel(7)">
                  <h3>Contactos</h3>
                  <figure>
                    <i class="material-icons">keyboard_arrow_down</i>
                  </figure>
                </a>
                <div class="req-body" :class="{open: this.show === 7}">
                  <p>Contestamos todas tus preguntas a través de nuestros 4 canales de atención al cliente:</p>
                  <ul>
                    <li>Por correo electrónico a <a href="mailto:informes@prestacash.com.ar">informes@prestacash.com.ar</a></li>
                    <li>Por <a href="https://www.facebook.com/prestacash.credito/" target="_blank">Facebook.com/prestacash.credito</a></li>
                    <li>Por web <a href="/">prestacashya.com.ar</a></li>
                    <li><a href="tel:+541123028606">+54-11-2302-8606</a></li>
                  </ul>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <!--  -->
</template>

<script>
import Velocity from 'velocity-animate';
var scrollToElement = require('scroll-to-element');
export default {
  components: {
  },
  data(){
    return{
      show: 0,
    }
  },
  mounted(){

  },
  methods:{
    scrolltoPin(element,duration){
        scrollToElement(element, {
          offset: -64,
          ease: 'in-out-sine',
          duration: duration
      });
    },
    setDisplayPanel(panel){
      this.show = panel;
    }
  },
}
</script>

<style lang="css" scoped>

</style>
