<template>
  <div>
    <!--  -->
    <div class="container" :key="this.key">
      <div class="row">
        <div class="col-12 col-md-8">
          <h1>Editar sucursal</h1>
        </div>

      </div>
      <div class="row justify-content-center">
        <div class="col-12 col-md-5">
          <div class="adm-sucursales-box">
            <div class="adm-sucursales-inner">

              <div class="adm-sucursales-item" v-if="sucursal.data">
                <form method="post" name="sucursalAdd" ref="sucursalAdd" @submit.prevent="" @keydown="sucursal.errors.clear($event.target.name)">

                  <div class="form-row">
                    <label for="name">Nombre</label>
                    <input type="text" name="name" v-model="sucursal.data.name">
                    <small v-text="sucursal.errors.get('name')"></small>
                  </div>

                  <div class="form-row">
                    <label for="address">Dirección</label>
                    <input type="text" name="address" v-model="sucursal.data.address">
                    <small v-text="sucursal.errors.get('address')"></small>
                  </div>

                  <div class="form-row">
                    <label for="phone">Teléfono</label>
                    <input type="text" name="phone" v-model="sucursal.data.phone">
                    <small v-text="sucursal.errors.get('phone')"></small>
                  </div>

                  <div class="form-row">
                    <label>Dirección</label>
                    <p>{{this.sucursal.data.address}}</p>
                    <div class="geoloc-control">
                      <gmap-autocomplete v-if="this.sucursal.data.address" :placeholder="this.sucursal.data.address" class="input"
                      @place_changed="setPlace">
                    </gmap-autocomplete>
                    <button class="btn-blue" @click="addMarker"><i class="material-icons">add</i></button>
                  </div>

                </div>

                  <div class="row">
                    <div class="col">
                      <div class="form-row">
                        <label for="address">Latitud</label>
                        <input type="text" name="lat" v-model="sucursal.data.lat">
                        <small v-text="sucursal.errors.get('lat')"></small>
                      </div>
                    </div>
                    <div class="col">
                      <div class="form-row">
                        <label for="address">Longitud</label>
                        <input type="text" name="lng" v-model="sucursal.data.lng">
                        <small v-text="sucursal.errors.get('lng')"></small>
                      </div>
                    </div>
                  </div>

                  <div class="form-row">
                    <label for="address">Transportes</label>
                    <input type="text" name="transports" v-model="sucursal.data.transports">
                    <span class="input-ref">Separados por coma</span>
                    <small v-text="sucursal.errors.get('transports')"></small>
                  </div>
                  <div class="form-row">
                    <label>Transportes: resultado</label>
                    <span>{{ this.transportsComp }}</span>
                  </div>

                  <div class="form-row">
                    <button type="button" name="submi" class="btn-rose" @click.prevent="guardarSucursal()">Guardar sucursal</button>
                  </div>

                </form>


              </div>



            </div>
          </div>
        </div>
        <div class="col-12 col-md-7">
          <div class="sucursale-map">

            <gmap-map v-if="this.sucursal.success && !this.sucursal.complete"
            :center="currentCenter"
            :zoom="zoom"
            style="width:100%;  height: 100%;"
            >

            <gmap-info-window :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen" @closeclick="infoWinOpen=false">
                <h4 class="infotooltipName">{{ infoContent.name }}</h4>
                <p class="infotooltipAddress">{{ infoContent.address }}</p>
            </gmap-info-window>

            <gmap-marker
            v-if="markerComp.position.lat != '' && markerComp.position.lng  != ''> 0"
            :position.sync="markerComp.position"
            :clickable="true"
            :draggable="false"
            :title="markerComp.name"
            @click="toggleInfoWindow(markerComp,1)"
            @mouseover="label = markerComp.name"
            @mouseout="statusText = null"
            ></gmap-marker>

          </gmap-map>

          </div>
        </div>

        <div class="loading-msg" v-if="sucursal.loading">
          <div class="loading-inner">
            <figure class="loading-icon">
              <div class="lds-hourglass"></div>
            </figure>
            <p>Estamos pensando</p>
          </div>
        </div>
        <div class="loading-msg" v-if="sucursal.complete">
          <div class="loading-inner">
            <figure class="success-icon">
              <div class="success-checkmark">
                <div class="check-icon">
                  <span class="icon-line line-tip"></span>
                  <span class="icon-line line-long"></span>
                  <div class="icon-circle"></div>
                  <div class="icon-fix"></div>
                </div>
              </div>
            </figure>
            <p>Procesamos con éxito!</p>
            <div class="loading-msg-cta">
              <button @click.prevent="$router.push('/sucursales');" class="btn-rose">Listo!</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--  -->
  </div>
</template>

<script>
import Form from './../../Classes/FormClass';
import Sucursales from './../../Classes/sucursalesClass';

export default {
  data(){
    return{
      key: 0,
      sucursal: new Sucursales(),
      center: { lat: -34.6412584, lng: -58.6268535},
      zoom: 10,
      places: [],
      currentPlace: null,
      markers:[],
      //Info Box
      infoContent: {
        name: '',
        address: '',
      },
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,
      //optional: offset infowindow so it visually sits nicely on top of our marker
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35
        }
      },
    }
  },
  created(){
    this.sucursal.show(this.$route.params.id);
  },
  mounted() {
this.updateDom();
 },
 computed:{
   currentCenter:{
     get: function(){
       if (this.sucursal.success) {
           return { lat: parseFloat(this.sucursal.data.lat), lng: parseFloat(this.sucursal.data.lng)}
     }else{
       return { lat: -34.6412584, lng: -58.6268535};
     }
   }
   },
   markerComp:{
     get: function(){
       let markerCoords = {
         lat: parseFloat(this.sucursal.data.lat),
         lng: parseFloat(this.sucursal.data.lng),
       };
       let tmpMarker = {
         name: this.sucursal.data.name,
         address: this.sucursal.data.address,
         position: markerCoords
       }
       return tmpMarker;
     }
   },
   transportsComp:{
     get: function(){
       return _.join(_.split(this.sucursal.data.transports,','),' / ');
     }
   }
 },
 methods: {
   // receives a place object via the autocomplete component
   updateDom(){
     this.key =+ 1;
   },
   setPlace(place) {
     this.currentPlace = place;
   },
   addMarker() {
     if (this.currentPlace) {
       const marker = {
         lat: this.currentPlace.geometry.location.lat(),
         lng: this.currentPlace.geometry.location.lng()
       };
       this.sucursal.data.lat = marker.lat;
       this.sucursal.data.lng = marker.lng;
       this.places.push(this.currentPlace);
       this.center = marker;
       this.currentPlace = null;
     }
   },
   geolocate: function() {
     navigator.geolocation.getCurrentPosition(position => {
       this.center = {
         lat: position.coords.latitude,
         lng: position.coords.longitude
       };
     });
   },
   toggleInfoWindow: function(marker, idx) {
     this.infoWindowPos = marker.position;
     this.infoContent.name = marker.name;
     this.infoContent.address = marker.address;
     //check if its the same marker that was selected if yes toggle
     if (this.currentMidx == idx) {
       this.infoWinOpen = !this.infoWinOpen;
     }
     //if different marker set infowindow to open and reset current marker index
     else {
       this.infoWinOpen = true;
       this.currentMidx = idx;
     }
   },
   guardarSucursal(){
     let formData = new FormData();
     if (!this.sucursal.data.phone) {
       this.sucursal.data.phone = '';
     }
     if (!this.sucursal.data.transports) {
       this.sucursal.data.transports = '';
     }
     formData.append('name', this.sucursal.data.name)
     formData.append('address',this.sucursal.data.address)
     formData.append('phone', this.sucursal.data.phone)
     formData.append('transports',this.sucursal.data.transports)
     formData.append('lat',this.sucursal.data.lat)
     formData.append('lng',this.sucursal.data.lng)
     this.sucursal.update(this.sucursal.data.id, formData);
   }
 }
}
</script>

<style lang="css" scoped>
.sucursale-map{
  height: 640px;
}
.infotooltipName{
  font-weight: bold;
}
</style>
