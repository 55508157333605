<template>
  <div>
    <!--  -->
    <div v-if="promociones.data.length > 0">
      <swiper :options="swiperOption" ref="promocionesSlider">
        <swiper-slide v-for="(slide, index) in promociones.data"  :key="promociones.data.id" class="promociones-item-slide">
          <a :href="slide.url">
            <img :src="slide.image" :alt="slide.name">
          </a>
        </swiper-slide>
      </swiper>

      <div class="swiper-promociones-pagination" slot="pagination"></div>
      <!-- If we need navigation buttons -->
      <div class="promociones-slide-navigation">
        <div class="swiper-button-prev"><i class='material-icons'>keyboard_arrow_left</i></div>
        <div class="swiper-button-next"><i class='material-icons'>keyboard_arrow_right</i></div>
      </div>
    </div>
    <!--  -->
  </div>
</template>

<script>
import SlideClass from './../Classes/SlideClass';
import { swiper, swiperSlide } from 'vue-awesome-swiper'
export default {
  components: {
    swiper,
    swiperSlide
 },
 data(){
   return{
      promociones : new SlideClass(),
      swiperOption: {
          slidesPerView: 1,
          spaceBetween: 30,
          centeredSlides: true,
          autoplay: {
            delay: 4000,
            disableOnInteraction: false
          },
          loop: true,
          pagination: {
            el: '.swiper-promociones-pagination',
            clickable: true
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          }
          //----

        }
   }
 },
 mounted(){
   this.promociones.list();

 },
 computed:{
   swiper() {
      return this.$refs.promocionesSlider.swiper;
    },
 },
}
</script>

<style lang="css" scoped>
.promociones-slide-container {
}
</style>
