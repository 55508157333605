<template>
  <div>
    <!--  -->
    <div class="solicitud-background" >
      <div class="solicitud-close-row">
        <a href="#" class="solicitud-close-cta" @click.prevent="$emit('cerrarSolicitud')">
          <i class="material-icons">
            highlight_off
          </i>
        </a>
      </div>

      <section class="container" :class="formStyle">
        <div class="row justify-content-center">
          <div class="col-12 col-md-4">
            <div class="form-card">
              <h1 class="contact-form-header"><slot></slot></h1>
              <form action="/" method="post" @submit.prevent="sendAsesor()" @keydown="form.errors.clear($event.target.name)">

              <div class="form-row floating">
                <input type="text" name="nombre" v-model="form.nombre" placeholder=" ">
                <label for="nombre">Nombre</label>
                <small class="error" v-text="form.errors.get('nombre')"></small>
              </div>
              <div class="form-row floating">
                <input type="text" name="apellido" v-model="form.apellido" placeholder=" ">
                <label for="apellido">Apellido</label>
                <small class="error" v-text="form.errors.get('apellido')"></small>
              </div>

              <div class="form-row floating">
                <input type="phone" name="telefono" v-model="form.telefono" placeholder=" ">
                <label for="telefono">Teléfono</label>
                <small class="error" v-text="form.errors.get('telefono')"></small>
              </div>
              <div class="form-row floating">
                <input type="text" name="mail" v-model="form.mail" placeholder=" ">
                <label for="mail">Mail</label>
                <small class="error" v-text="form.errors.get('mail')"></small>
              </div>
              <div class="form-row" v-if="this.provincias">
                <autocomplete
                placeholder="Provincia"
                initialValue="Buenos Aires"
                :showNoResults="this.noresul"
                name="provincia"
                resultsValue="name"
                v-model="form.provincia"
                @selected="provinciaSelected"
                :source="this.provincias">
              </autocomplete>
              <small class="error" v-text="form.errors.get('provincia')"></small>
            </div>
              <div class="form-row" v-if="this.localidades">
                <autocomplete
                placeholder="localidad"
                :showNoResults="this.noresul"
                name="localidad"
                resultsValue="name"
                v-model="form.localidad"
                @selected="localidadSelected"
                :source="this.localidades">
              </autocomplete>
              <small class="error" v-text="form.errors.get('localidad')"></small>
            </div>
              <div class="form-cta-row">
                <button type="submit" class="btn-primary block" name="enviar-contacto" :disabled="form.errors.any()">Solicitar</button>
              </div>
            </form>
              <div v-if="form.success"class="form-msg">
                <div class="form-msg-content">
                  <figure>
                    <div class="success-checkmark">
                      <div class="check-icon">
                        <span class="icon-line line-tip"></span>
                        <span class="icon-line line-long"></span>
                        <div class="icon-circle"></div>
                        <div class="icon-fix"></div>
                      </div>
                    </div>
                  </figure>
                  <p>Recibimos tu solicitud con éxito</p>
                  <div class="form-cta-row">
                    <a href="#" @click.prevent="form.success = false" class="btn-blue">Listo</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
    <!--  -->
  </div>
</template>

<script>
import Form from '../Classes/FormClass';
import Autocomplete from 'vuejs-auto-complete'
export default {
  components:{
    Autocomplete,
  },
  props:[
    'formTitle',
    'formColor',
  ],
  data(){
    return{
      form: new Form({
        'nombre':'',
        'apellio':'',
        'mail':'',
        'phone':'',
        'provincia': '',
        'localidad':'',
      }),
      noresul: false,
      provincias: new Array(),
      localidades: new Array(),
      provinciaResult: null,
      localidadResult: null,
      formStyle: this.formColor,
    }
  },
  created(){
    this.getProvincias();
  },
  mounted(){

  },
  watch:{

  },
  computed:{

  },
  methods:{
    sendAsesor(){
      this.form.post('/asesores/store');
    },
    getProvincias(){
      var self = this
      axios.get('https://apis.datos.gob.ar/georef/api/provincias?campos=nombre,id')
      .then(response =>{
        _.forEach(response.data.provincias, function(value, key) {
          let temProv = {};
          temProv.id = value.id;
          temProv.name = value.nombre;
          self.provincias.push(temProv)
        });

      })
      .catch(error =>{

      });
    },
    getLocalidadFromProvincia(name){
      var self = this
      axios.get('https://apis.datos.gob.ar/georef/api/localidades?provincia='+name+'&campos=nombre,id&max=1000')
      .then(response =>{
        _.forEach(response.data.localidades, function(value, key) {
          let temLoc = {};
          temLoc.id = value.id;
          temLoc.name = value.nombre;
          self.localidades.push(temLoc)
        });

      })
      .catch(error =>{

      });
    },

    provinciaSelected(provincia){
      this.provinciaResult = provincia.selectedObject;
      this.getLocalidadFromProvincia(this.provinciaResult.name);
    },
    localidadSelected(localidad){
      this.localidadResult = localidad.selectedObject;
    },
  }
}
</script>

<style lang="css" scoped>
</style>
