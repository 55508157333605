<template>
  <div>
    <!--  -->
    <div class="form-card" >
      <h1 class="contact-form-header"><slot></slot></h1>
      <form action="/" method="post" @submit.prevent="sendContact()" @keydown="form.errors.clear($event.target.name)">
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-row floating">
            <input type="text" name="name" v-model="form.name" placeholder=" ">
            <label for="nombre">Nombres</label>
            <small class="error" v-text="form.errors.get('name')"></small>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-row floating">
            <input type="text" name="surName" v-model="form.surName" placeholder=" ">
            <label for="surName">Apellidos</label>
            <small class="error" v-text="form.errors.get('surName')"></small>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-row floating">
            <input type="text" name="dni" v-model="form.dni" placeholder=" ">
            <label for="dni">DNI</label>
            <small class="error" v-text="form.errors.get('dni')"></small>
          </div>
        </div>
        <div class="col-12 col-md-6" v-if="propTipoPrestamo == 'simulador'">
          <div class="form-row radio-row">
            <div class="input-radio-row" :key="this.keyUpdate">
              <a href="#"  @click.prevent="sexoSelect('sexo','f')" :class="{'selected': this.sexoRadio == 'f'}">
                  Femenino
              </a>
              <a href="#" @click.prevent="sexoSelect('sexo','m')" :class="{'selected': this.sexoRadio == 'm'}">
                  Masculino
              </a>
            </div>
            <small class="error" v-text="form.errors.get('sexo')"></small>
          </div>
        </div>
      </div>

      <!-- sexo radio -->

      <div class="phone-row">
        <div class="phone-area">
          <div class="form-row floating">
            <input type="phone" name="phone" maxlength="4" v-model="form.area" placeholder=" ">
            <label for="phone">Area</label>
            <small class="error" v-text="form.errors.get('area')"></small>
          </div>
        </div>
        <div class="phone-number">
          <div class="form-row floating">
            <input type="phone" name="phone"  maxlength="10" v-model="form.phone" placeholder=" ">
            <label for="phone">Teléfono</label>
            <small class="error" v-text="form.errors.get('phone')"></small>
          </div>
        </div>
      </div>

      <div class="form-row floating">
        <input type="text" name="email" v-model="form.email" placeholder=" ">
        <label for="email">Mail</label>
        <small class="error" v-text="form.errors.get('email')"></small>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-row" v-if="this.provincias">
            <autocomplete
            placeholder="Provincia"
            initialValue="Buenos Aires"
            :showNoResults="this.noresul"
            name="provincia"
            resultsValue="name"
            v-model="form.provincia"
            @selected="provinciaSelected"
            :source="orderedProvincias">
          </autocomplete>
          <small class="error" v-text="form.errors.get('provincia')"></small>
        </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-row" v-if="this.localidades">
            <autocomplete
            placeholder="localidad"
            :showNoResults="this.noresul"
            name="localidad"
            resultsValue="name"
            v-model="form.localidad"
            @selected="localidadSelected"
            :source="orderedLocalidades">
          </autocomplete>
          <small class="error" v-text="form.errors.get('localidad')"></small>
        </div>
        </div>
      </div>


    <div class="form-row radio-row" v-if="propTipoPrestamo == 'simulador'">
      <label for="">Documentación</label>
      <div class="input-radio-row radio-lg" :key="this.keyUpdate">
        <a href="#" @click.prevent="docSelect('recibo','r')" :class="{'selected': this.documentacionRadio == 'r'}">
            Recibo de sueldo
        </a>
        <a href="#" @click.prevent="docSelect('recibo','d')" :class="{'selected': this.documentacionRadio == 'd'}">
            DNI
        </a>
      </div>
      <small class="error" v-text="form.errors.get('recibo')"></small>
    </div>

    <div class="form-row" v-if="propTipoPrestamo == 'simulador'">
      <div class="slider-info">
        <label>Monto a pedir: <span>${{this.form.monto}}</span></label>
      </div>
      <div class="slider-row">
        <div class="slider-box">
          <vue-slider
          v-model="form.monto"
          :data="montoData"
          :marks="true"
          dotSize="24"
          height="8px"
          :contained="true"
          :clickable="true"
          tooltip="none"
          railStyle="sliderRail"
          :process-style="{ backgroundColor: '#F58634' }"
          >
        </vue-slider>
      </div>
    </div>
  </div>
    <div class="form-row" v-if="propTipoPrestamo == 'simulador'">
      <div class="slider-info">
        <label>Plazo: <span>{{this.form.plazo}} cuotas</span></label>
      </div>
      <div class="slider-row">
        <div class="slider-box">
          <vue-slider
          v-model="form.plazo"
          :data="plazoData"
          :marks="true"
          dotSize="24"
          height="8px"
          :contained="true"
          :clickable="true"
          tooltip="none"
          railStyle="sliderRail"
          :process-style="{ backgroundColor: '#F58634' }"
          >
        </vue-slider>
      </div>
    </div>
  </div>
  <div class="slider-infor-results" v-if="(propTipoPrestamo == 'simulador') && (this.planes.success)">
    <h4>Detalle del préstamo</h4>
    <p><label>
      <i class="material-icons">
        keyboard_arrow_right
      </i>
      Monto a pedir</label>
      $ {{this.form.monto}}
    </p>
    <p><label>
      <i class="material-icons">
        keyboard_arrow_right
      </i>
      Plazo:</label>
      {{this.form.plazo}} cuotas
    </p>
    <p ><label>
      <i class="material-icons">
        keyboard_arrow_right
      </i>
      Monto a devolver:</label>
      <vue-numeric currency="$" separator="." v-model="getFullAmount.total" read-only></vue-numeric>
    </p>
    <p><label>
      <i class="material-icons">
        keyboard_arrow_right
      </i>
      Valor de la cuota:</label>
      <vue-numeric currency="$" separator="." v-model="getFullAmount.cuota" read-only></vue-numeric>
    </p>
  </div>

    <!-- objetivo select -->
    <!-- recibo radio -->
    <!-- monto -->
    <!-- plazo -->
    <input type="hidden" name="tipoPrestamo" v-model="form.tipoPrestamo">
      <div class="form-cta-row">
        <button type="submit" class="btn-primary block" name="enviar-contacto" :disabled="form.errors.any()">Pedilo ahora</button>
      </div>
    </form>
      <div v-if="form.success"class="form-msg">
        <div class="form-msg-content">
          <figure>
            <div class="success-checkmark">
              <div class="check-icon">
                <span class="icon-line line-tip"></span>
                <span class="icon-line line-long"></span>
                <div class="icon-circle"></div>
                <div class="icon-fix"></div>
              </div>
            </div>
          </figure>
          <p>Recibimos tu solicitud con éxito, <br> en breve nos pondremos en contacto!</p>
          <div class="form-cta-row">
            <a href="#" @click.prevent="form.success = false" class="btn-blue">Listo</a>
          </div>
        </div>
      </div>
      <div v-if="form.error"class="form-msg">
        <div class="form-msg-content">
          <figure>
            <div class="success-checkmark">
              <div class="error-msg">
                <figure>
                  <i class="material-icons">
                    cancel
                  </i>
                </figure>
              </div>
            </div>
          </figure>
          <p>Tuvimos un error con tu solicitud</p>
          <div class="form-cta-row">
              <a href="#" @click.prevent="form.error = false" class="btn-blue">Intentar nuevamente</a>
          </div>
        </div>
      </div>
      <div class="loading-msg" v-if="this.form.loading && !this.form.error && !this.form.success">
        <div class="loading-inner">
          <div class="lds-hourglass"></div>
          <div class="loading-msg-cta">
            Estamos enviando tu consulta.
          </div>
        </div>
      </div>
    </div>
    <!--  -->
  </div>
</template>

<script>

import Form from '../Classes/FormClass';
import Contacto from '../Classes/ContactoClass';
import Autocomplete from 'vuejs-auto-complete';
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';
import VueNumeric from 'vue-numeric'

export default {
  components: {
    Autocomplete,
    VueSlider,
    VueNumeric,
  },
  props:[
    'propTipoPrestamo'
  ],
  data(){
    return{
      form: new Form({
        'name':'',
        'surName':'',
        'email':'',
        'area':'',
        'phone':'',
        'dni':'',
        'sexo':'',
        'provincia': '',
        'localidad':'',
        'recibo':'',
        'monto':'3.000',
        'plazo':1,
        'tipoPrestamo': this.propTipoPrestamo,

      }),
      noresul: false,
      provincias: new Array(),
      localidades: new Array(),
      provinciaResult: null,
      localidadResult: null,
      montoData: ['3.000','7.000','10.000','15.000','20.000'],
      plazoData:[1,2,3,4,5,6,8,10,12],
      sexoRadio: null,
      documentacionRadio: null,
      keyUpdate : 0,
      planes: new Contacto(),
      montoTotalADevolver:0,
      valordeCuotaADevolver:0,
    }
  },
  created(){
    this.getProvincias();
    this.planes.getPlanes();
  },
  computed:{
    orderedProvincias:{
      get: function(){
        if (this.provincias) {
          return _.sortBy(this.provincias,['name']);
        }
      }
    },
    getFullAmount:{
      get:function(){
        if(this.planes.data){
          let field =  _.find(this.planes.data,{'cuotas':this.form.plazo});
          let monto = _.replace(this.form.monto,'.','');
          return {
            'total':_.ceil(_.toNumber(field.coheficiente)*(_.toNumber(monto)*_.toInteger(this.form.plazo))),
            'cuota':_.ceil((_.toNumber(monto)*_.toNumber(field.coheficiente)))
          }
        }
      }
    },
    orderedLocalidades:{
      get: function(){
        if (this.localidades) {
          return _.sortBy(this.localidades,['name']);
        }
      }
    }
  },
  methods:{
    sendContact(){
      this.form.post('/contactos/insert');
    },
    getProvincias(){
      var self = this
      axios.get('https://apis.datos.gob.ar/georef/api/provincias?campos=nombre,id')
      .then(response =>{
        _.forEach(response.data.provincias, function(value, key) {
          let temProv = {};
          temProv.id = value.id;
          temProv.name = value.nombre;
          self.provincias.push(temProv)
        });

      })
      .catch(error =>{

      });
    },
    getLocalidadFromProvincia(name){
      var self = this
      axios.get('https://apis.datos.gob.ar/georef/api/localidades?provincia='+name+'&campos=nombre,id&max=1000')
      .then(response =>{
        _.forEach(response.data.localidades, function(value, key) {
          let temLoc = {};
          temLoc.id = value.id;
          temLoc.name = value.nombre;
          self.localidades.push(temLoc)
        });

      })
      .catch(error =>{

      });
    },
    provinciaSelected(provincia){
      this.provinciaResult = provincia.selectedObject;
      this.getLocalidadFromProvincia(this.provinciaResult.name);
    },
    localidadSelected(localidad){
      this.localidadResult = localidad.selectedObject;
    },
    updateDOM(){
      this.keyUpdate++;
    },
    sexoSelect(name,value){
      this.form.errors.clear(name);
      this.sexoRadio = value;
      if (value === 'f') {
        this.form.sexo = 'Femenino';
      }else if (value === 'm') {
        this.form.sexo = 'Masculino';
      }
      this.updateDOM();
    },
    docSelect(name,value){
      this.form.errors.clear(name);
      this.documentacionRadio = value;
      if (value === 'r') {
        this.form.recibo = 'Recibo de sueldo';
      }else if (value === 'd') {
        this.form.recibo = 'DNI';
      }
      this.updateDOM();
    },

  }
}
</script>

<style lang="scss" scoped>

  .error-msg{
    i{
      font-size: 84px;
      color: #ec6d6d;
    }
  }

</style>
