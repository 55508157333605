<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-8">
        <h1>Banner de promoción v3</h1>
      </div>
    </div>

    <BannerInsert></BannerInsert>



  </div>
</template>

<script>


import BannerInsert from '../components/banner/bannerInsert.vue';


export default {
  components : {
    BannerInsert,
  },
  data(){
    return{}
  }

}
</script>
