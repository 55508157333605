<template>
  <!--  -->
  <div>
    <h1 class="contact-form-header">ESTÁS A UN PASO DE OBTENER TU PRÉSTAMO</h1>
    <form action="/" method="post" @submit.prevent="sendContact()" @keydown="form.errors.clear($event.target.name)">
      <div class="simulation-disclaimer">
        <p>Completá con tus datos personales.</p>
      </div>


        <div class="row">
          <div class="col-12 col-md-6">
            <div class="form-row floating">
              <input type="text" name="name" v-model="form.name" placeholder=" ">
              <label for="nombre">Nombres</label>
              <small class="error" v-text="form.errors.get('name')"></small>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-row floating">
              <input type="text" name="surName" v-model="form.surName" placeholder=" ">
              <label for="surName">Apellidos</label>
              <small class="error" v-text="form.errors.get('surName')"></small>
            </div>
          </div>
        </div>


      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-row floating">
            <input type="text" name="dni" v-model="form.dni" placeholder=" ">
            <label for="dni">DNI</label>
            <small class="error" v-text="form.errors.get('dni')"></small>
          </div>
        </div>

        <div class="col-12 col-md-6">
          <div class="form-row fixed">
            <label for="nacimiento">Fecha de nacimiento</label>
            <!-- <input type="text" name="dni" v-model="form.nacimiento" placeholder=" "> -->
            <the-mask :mask="['##/##/####']"  v-model="unformatedDate" placeholder="dd/mm/aaaa"/>

            <small class="error" v-text="form.errors.get('nacimiento')"></small>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="form-row radio-row">
            <label>Sexo</label>
            <div class="input-radio-row" :key="this.keyUpdate">
              <a href="#"  @click.prevent="sexoSelect('sexo','f')" :class="{'selected': this.sexoRadio == 'f'}">
                  Femenino
              </a>
              <a href="#" @click.prevent="sexoSelect('sexo','m')" :class="{'selected': this.sexoRadio == 'm'}">
                  Masculino
              </a>
            </div>
            <small class="error" v-text="form.errors.get('sexo')"></small>
          </div>
        </div>
      </div>

      <div class="phone-row">
        <div class="phone-area">
          <div class="form-row floating">
            <input type="phone" name="phone" maxlength="4" v-model="form.area" placeholder=" ">
            <label for="phone">Area</label>
            <small class="error" v-text="form.errors.get('area')"></small>
          </div>
        </div>
        <div class="phone-number">
          <div class="form-row floating">
            <input type="phone" name="phone"  maxlength="10" v-model="form.phone" placeholder=" ">
            <label for="phone">Teléfono</label>
            <small class="error" v-text="form.errors.get('phone')"></small>
          </div>
        </div>
      </div>

      <div class="form-row floating">
        <input type="text" name="email" v-model="form.email" placeholder=" ">
        <label for="email">Mail</label>
        <small class="error" v-text="form.errors.get('email')"></small>
      </div>

      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-row" v-if="this.provincias">
            <autocomplete
            placeholder="Provincia"
            initialValue="Buenos Aires"
            :showNoResults="this.noresul"
            name="provincia"
            resultsValue="name"
            v-model="form.provincia"
            @selected="provinciaSelected"
            :source="orderedProvincias">
          </autocomplete>
          <small class="error" v-text="form.errors.get('provincia')"></small>
        </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-row" v-if="this.localidades">
            <autocomplete
            placeholder="localidad"
            :showNoResults="this.noresul"
            name="localidad"
            resultsValue="name"
            v-model="form.localidad"
            @selected="localidadSelected"
            :source="orderedLocalidades">
          </autocomplete>
          <small class="error" v-text="form.errors.get('localidad')"></small>
        </div>
        </div>
      </div>

      <div class="form-row radio-row">
        <label for="">¿Tenés cuenta bancaria?</label>
        <div class="input-radio-row radio-lg" :key="this.keyUpdate">
          <a href="#" @click.prevent="cuentaSelect('cuenta',1)" :class="{'selected': this.cuentaRadio == 1}">
              Si
          </a>
          <a href="#" @click.prevent="cuentaSelect('cuenta',0)" :class="{'selected': this.cuentaRadio == 0}">
              No
          </a>
        </div>
        <small class="error" v-text="form.errors.get('cuenta')"></small>
      </div>

      <div class="form-row radio-row">
        <label for="">Tipo de relación laboral</label>
        <v-select v-model="form.recibo" :searchable="false" placeholder="Seleccioná tu condición laboral" name="recibo" @input="docSelect" :options="condicionLaboral"/>
        <small class="error" v-text="form.errors.get('recibo')"></small>
      </div>

      <div class="form-cta-row">
        <button type="submit" class="btn-primary block" name="enviar-contacto" :disabled="form.errors.any()">Pedilo ahora</button>
      </div>

<!-- Response -->
      <div v-if="form.success"class="form-msg">
        <div class="form-msg-content">
          <figure>
            <div class="success-checkmark">
              <div class="check-icon">
                <span class="icon-line line-tip"></span>
                <span class="icon-line line-long"></span>
                <div class="icon-circle"></div>
                <div class="icon-fix"></div>
              </div>
            </div>
          </figure>
          <p>Recibimos tu solicitud con éxito, <br> en breve nos pondremos en contacto!</p>
          <div class="form-cta-row">
            <a href="#" @click.prevent="endForm()" class="btn-blue">Ver requisitos</a>
          </div>
        </div>
      </div>

<!-- error -->
      <div v-if="form.error"class="form-msg">
        <div class="form-msg-content">
          <figure>
            <div class="success-checkmark">
              <div class="error-msg">
                <figure>
                  <i class="material-icons">
                    cancel
                  </i>
                </figure>
              </div>
            </div>
          </figure>
          <p>Tuvimos un error con tu solicitud</p>
          <div class="form-cta-row">
              <a href="#" @click.prevent="form.error = false" class="btn-blue">Intentar nuevamente</a>
          </div>
        </div>
      </div>

      <!-- loading -->

      <div class="loading-msg" v-if="this.form.loading && !this.form.error && !this.form.success">
        <div class="loading-inner">
          <div class="lds-hourglass"></div>
          <div class="loading-msg-cta">
            Estamos enviando tu consulta.
          </div>
        </div>
      </div>

    </form>
  </div>
  <!--  -->
</template>

<script>

import Form from '../../Classes/FormClass';
import Contacto from '../../Classes/ContactoClass';
import Autocomplete from 'vuejs-auto-complete';
import {TheMask} from 'vue-the-mask';
import moment from 'moment';
var scrollToElement = require('scroll-to-element');
import Events from '../../Classes/Events';
import vSelect from 'vue-select';

export default {
  components: {
    Autocomplete,
    TheMask,
    vSelect,
  },
  data(){
    return{
      events: new Events('UA-116927900-1'),
      form: new Form({
        'name':'',
        'surName':'',
        'email':'',
        'area':'',
        'phone':'',
        'dni':'',
        'sexo':'',
        'provincia': '',
        'localidad':'',
        'recibo':'',
        'cuenta':'',
        'nacimiento':'',
        'monto':'',
        'plazo':'',
        'cuota':'',
      }),
      noresul: false,
      provincias: new Array(),
      localidades: new Array(),
      provinciaResult: null,
      localidadResult: null,
      sexoRadio: null,
      cuentaRadio: null,
      keyUpdate : 0,
      unformatedDate: '',
      condicionLaboral: [
        'Recibo de sueldo',
        'DNI',
        'Jubilado - Pensionado',
        'Autónomo - Monotributista',
        'Empleada doméstica',
        'Beneficios especiales'
      ]
    }
  },
  created(){
    this.getProvincias();
  },
  mounted(){
    var self = this;
    window.addEventListener('unload', function(event) {
      self.events.setEvent('Datos-personales','Datos-personales:Abandono', 'Close window', 'Abandono');
     });
  },
  computed:{
    orderedProvincias:{
      get: function(){
        if (this.provincias) {
          return _.sortBy(this.provincias,['name']);
        }
      }
    },
    orderedLocalidades:{
      get: function(){
        if (this.localidades) {
          return _.sortBy(this.localidades,['name']);
        }
      }
    }
  },
  methods:{
    scrolltoPin(element,duration){
        scrollToElement(element, {
          offset: -200,
          ease: 'in-out-sine',
          duration: duration
      });
    },
    setDate(date){
      return moment(date,'DD/MM/YYYY').format('YYYY-MM-DD');
    },
    endForm(){
      this.scrolltoPin('.requisitos',1000);
      this.$router.push('/');
      this.form.success = false;
    },
    sendContact(){
      this.form.monto = this.$store.getters.getMonto;
      this.form.plazo = this.$store.getters.getPlazo;
      this.form.cuota = this.$store.getters.getCuotas;

      if (this.unformatedDate) {
        this.form.nacimiento = this.setDate(this.unformatedDate);
      }else{
        this.unformatedDate = '';
      }

      this.events.setEvent('Datos-personales','Datos-personales:Completa formulario', 'Formulario completo', 'Completa formulario');
      this.events.setEvent('Datos-personales','Datos-personales: provincia', 'Pedido de prestamo desde: '+this.form.provincia, 'Pedido de prestamo: provincia');
      this.events.setEvent('Datos-personales','Datos-personales: localidad', 'Pedido de prestamo desde: '+this.form.localidad, 'Pedido de prestamo: localidad');
      this.events.setEvent('Datos-personales','Datos-personales: sexo', 'Pedido de prestamo: genero '+this.form.sexo, 'Pedido de prestamo: genero');
      this.events.setEvent('Datos-personales','Datos-personales: relacion laboral', 'Pedido de prestamo: relacion laboral '+this.form.recibo, 'Pedido de prestamo: relacion laboral');

      let cuentaBancariaBool = null;
      if (this.form.cuenta == 1) {
        cuentaBancariaBool = 'SI';
      }else if (this.form.cuenta == 0) {
        cuentaBancariaBool = 'NO';
      }
      this.events.setEvent('Datos-personales','Datos-personales: cuenta bancaria', 'Pedido de prestamo: cuenta bancaria '+cuentaBancariaBool, 'Pedido de prestamo: cuenta bancaria');
      this.form.post('/contactos/insert');
    },
    getProvincias(){
      var self = this
      axios.get('https://apis.datos.gob.ar/georef/api/provincias?campos=nombre,id')
      .then(response =>{
        _.forEach(response.data.provincias, function(value, key) {
          let temProv = {};
          temProv.id = value.id;
          temProv.name = value.nombre;
          self.provincias.push(temProv)
        });

      })
      .catch(error =>{

      });
    },
    getLocalidadFromProvincia(name){
      var self = this
      axios.get('https://apis.datos.gob.ar/georef/api/localidades?provincia='+name+'&campos=nombre,id&max=1000')
      .then(response =>{
        _.forEach(response.data.localidades, function(value, key) {
          let temLoc = {};
          temLoc.id = value.id;
          temLoc.name = value.nombre;
          self.localidades.push(temLoc)
        });

      })
      .catch(error =>{

      });
    },
    provinciaSelected(provincia){
      this.provinciaResult = provincia.selectedObject;
      this.getLocalidadFromProvincia(this.provinciaResult.name);
      this.form.errors.clear('provincia');
    },
    localidadSelected(localidad){
      this.localidadResult = localidad.selectedObject;
      this.form.errors.clear('localidad');
    },
    updateDOM(){
      this.keyUpdate++;
    },
    sexoSelect(name,value){
      this.form.errors.clear(name);
      this.sexoRadio = value;
      if (value === 'f') {
        this.form.sexo = 'Femenino';
      }else if (value === 'm') {
        this.form.sexo = 'Masculino';
      }
      this.updateDOM();
    },
    docSelect(){
      this.form.errors.clear('recibo');
      this.updateDOM();
    },
    cuentaSelect(name,value){
      this.form.errors.clear(name);
      this.cuentaRadio = value;
      this.form.cuenta = value;
      this.updateDOM();
    },

  }

}
</script>

<style lang="scss" scoped>
</style>
