<template>
  <div>
    <!--  -->
    <header>
      <div class="container">
        <div class="row justify-content-between">
          <div class="col-6">
            <figure class="logo">
              <router-link to="/">
                <img src="/img/prestacash-logo.png" alt="Prestacash">
              </router-link>
            </figure>
          </div>
        <div class="col-6">
          <div class="social-icons">
            <ul>
              <li><a href="" target="_blank">
                <figure><img src="/img/facebook-icon-white.svg" alt="Prestacash en Facebook"></figure>
              </a></li>
              <li><a href="" target="_blank">
                <figure><img src="/img/instagram-icon-white.svg" alt="Prestacash en Instagram"></figure>
              </a></li>
              <li><a href="" target="_blank">
                <figure><img src="/img/mail-icon-white.svg" alt="Envianos un mail"></figure>
              </a></li>
            </ul>
          </div>
        </div>

        </div>
      </div>
    </header>


    <section class="comercios-adheridos-header">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-sm-12 col-md-10">
              <h1>Comercios adheridos</h1>
            </div>
          </div>
        </div>
    </section>

    <div class="container-fluid comercios-filters" id="topFilters">
      <div class="row justify-content-center">
        <router-link to="/" class="volver-de-comercios"><i class="material-icons">keyboard_arrow_left</i> volver</router-link>
        <div class="col-10 comercios-filert-content">
          <form action="" name="comercios-adheridos-filter">
            <div class="form-row">
              <select class="" name="localidadesSelect" v-model="localidad"  v-if="this.localidades.success">
                <option value="all">Localidad</option>
                <option :value="localidad.localidad" v-for="localidad in localidades.data">{{localidad.localidad}}</option>
              </select>
            </div>
            <div class="form-row">
              <select class="" name="localidadesSelect" v-model="rubro" v-if="this.rubros.success">
                <option value="all">Rubro</option>
                <option :value="rubro.rubro" v-for="rubro in rubros.data">{{rubro.rubro}}</option>
              </select>
            </div>
                <!-- <router-link to="/comercios/">comercios</router-link> -->
              <button class="btn-primary-invert search-btn" name="enviar-contacto" @click.prevent="changeFilter()"><i class="material-icons">search</i></button>

          </form>
        </div>
      </div>


    </div>

    <div class="container">
      <div class="row justify-content-center">
        <div class="col-8">
          <p class="filter-result-info">
            Mostrando resultados para {{this.showLocalidad}} y {{this.showRubro}}</p>
        </div>
      </div>
    </div>

    <section class="container" v-if="this.comercios.success && this.comercios.data">
      <div class="row">
        <div class="col-12">
          <div class="table-comercios-adheridos-box">

              <div class="table-comercios-adheridos header">
                <div class="data-table-item">Local</div>
                <div class="data-table-item">Rubro</div>
                <div class="data-table-item">Dirección</div>
                <div class="data-table-item">Localidad</div>
              </div>

                <div class="table-comercios-adheridos content" v-for="(comercio, index) in getComercios.data"  :key="comercio.id">
                  <div class="data-table-item">{{comercio.nombre}}</div>
                  <div class="data-table-item">{{comercio.rubro}}</div>
                  <div class="data-table-item">{{comercio.domicilio}}</div>
                  <div class="data-table-item">{{comercio.localidad}}</div>
                </div>

                <div v-if="this.comercios.success && this.comercios.data.data == ''">
                  <p class="no-result">No se encontraron resultados</p>
                </div>

          </div>

          <div class="row justify-content-between pagination-row">
            <div class="col-12 col-md-8 pagination-box">
              <pagination :data="getComercios" @pagination-change-page="getResults" :limit="pageViews"></pagination>
            </div>
            <div class="col-12 col-md-4 pagination-box viewItems">
              <label for="CantPerView">Mostrar:</label>
              <select name="CantPerView" v-model="itemsPerShow" @change="changeItemsPerView()" class="select-coemrcios-cant-items">
                <option value="15">15</option>
                <option value="25">25</option>
                <option value="50">50</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section class="comercios">
      <div class="row justify-content-end no-gutters comercios-img-back">
        <div class="col-12 col-md-6 ">
          <figure class="comercios-image-back">
            <img src="/img/comercios-back.png" alt="Comercios">
          </figure>
        </div>
      </div>

      <div class="container comercios-content">
        <div class="row justify-content-start">
          <div class="col-12 col-md-12 col-lg-5">
            <div class="comercios-box">
              <h2>Comercios</h2>
              <p>Estimado Comerciante</p>
              <p>Tenemos el agrado de dirigirnos a usted para invitarlo a
              incorporarse a nuestra cartera de más de 4000 comercios adheridos en zona Norte,
              y de esa manera seguir ofreciendo una importante cantidad y calidad de productos a sus clientes</p>
              <p>A tal efecto queremos poner en su conocimiento nuestro sistema práctico,
                fácil y seguro de trabajo donde usted podrá realizar un crédito sin moverse de su comercio.</p>
              <p>ES IMPORTANTE DESTACAR QUE TODAS LAS OPERACIONES
                LAS PUEDE COBRAR EN EL ACTO Y SIN DESCUENTOS EN CUALQUIERA DE NUESTRAS SUCURSALES.</p>
              <ul>
                <li>
                  <figure><img src="/img/pago-efectivo-en-el-momento.svg" alt="Pago efectivo en el momento"></figure>
                  <p>Pago efectivo<br>en el momento</p>
                </li>
                <li>
                  <figure><img src="/img/atencion-y-rapidez.svg" alt="Atención y Rapidez"></figure>
                  <p>Atención y<br> Rapidez</p>
                </li>
                <li>
                  <figure><img src="/img/promociones-fechas-especiales.svg" alt="Promociones en fechas especiales"></figure>
                  <p>Promociones<br> en fechas especiales</p>
                </li>
                <li>
                  <figure><img src="/img/promocionamos-tu-comercio.svg" alt="Promocionamos su comercio"></figure>
                  <p>Promocionamos <br>su comercio</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

    </section>

    <footer>
      <div class="container">
        <div class="row">
        <div class="col-6">
          <div class="social-icons">
            <ul>
              <li><a href="" target="_blank">
                  <figure><img src="/img/facebook-icon-white.svg" alt="Prestacash en Facebook"></figure>
                </a></li>
              <li><a href="" target="_blank">
                  <figure><img src="/img/instagram-icon-white.svg" alt="Prestacash en Instagram"></figure>
                </a></li>
              <li><a href="" target="_blank">
                  <figure><img src="/img/mail-icon-white.svg" alt="Envianos un mail"></figure>
                </a></li>
            </ul>
          </div>
        </div>
        <div class="col-6">
          <div class="okame-logo">
            <figure>
              <a href="https://www.okame.com.ar/" target="_blank">
              <img src="/img/okame-logo.svg" alt="Developed by Okame">
              </a>
            </figure>
          </div>
        </div>
      </div>
      </div>
    </footer>
    <!--  -->
  </div>
</template>

<script>
import Comercio from './../Classes/ComerciosClass.js';
import pagination from 'laravel-vue-pagination';
import msg from './../components/msg';
var VueScrollTo = require('vue-scrollto');
export default {
  components:{
    pagination,
    msg,
  },
  data(){
    return{
      comercios: new Comercio(),
      rubros: new Comercio(),
      import: new Comercio(),
      files: [],
      pageViews: 5,
      itemsPerShow: 15,
      actualPage: null,
      localidad: this.$store.getters.getLocalidad,
      rubro: this.$store.getters.getRubro,
      localidades : new Comercio(),
      showImport: false,
      orderDefault:{
        order1:'localidad',
        order2:'rubro'
      },
      test: null,
    }
  },
  created(){

    this.comercios.list(1, this.itemsPerShow, this.setDataToSend(true));
    this.localidades.getLocalidades();
    this.rubros.getRubros();
  },
  computed:{
    getLocalidad(){
      return this.$store.getters.getLocalidad;
    },
    getRubro(){
      return this.$store.state.rubro;
    },
    showRubro(){
      if (this.$store.state.rubro == 'all') {
          return 'todos los rubros';
      }else{
        return this.$store.state.rubro;
      }
    },
    showLocalidad(){
      if (this.$store.state.localidad == 'all') {
          return 'todas las localidades';
      }else{
        return this.$store.state.localidad;
      }
    },
    getComercios:{
      get:function(){
        if (this.comercios.success) {
          this.actualPage = this.comercios.data.current_page;
          return this.comercios.data;
        }

      }
    }
  },
  methods:{
    getResults(page){
      this.comercios.list(page, this.itemsPerShow, this.setDataToSend(false));
      VueScrollTo.scrollTo('#topFilters',500);
    },
    setDataToSend(storeData){

      let filterData = new FormData();

      if (storeData) {
        filterData.append('localidad', this.$store.state.localidad);
        filterData.append('rubro', this.getRubro);
      }else{
        filterData.append('localidad', this.localidad);
        filterData.append('rubro', this.rubro);
        this.$store.commit('setLocalidad',this.localidad);
        this.$store.commit('setRubro',this.rubro);
      }

      filterData.append('order1', this.orderDefault.order1);
      filterData.append('order2', this.orderDefault.order2);

      return filterData;
    },
    changeFilter(){
      this.getResults(1);
    },
    changeItemsPerView(){
      this.getResults(this.actualPage);
    },
  },
}
</script>

<style lang="scss" scoped>
.no-result{
  display: block;
  padding: 16px;
  text-align: center;
  font-size: 18px;
}
</style>
