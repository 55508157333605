<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-8">
        <h1>Agregar Slide</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-8">
        <a href="" class="btn-rose">Ordenar</a>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-12">
        <div class="okm-banner-box" v-show="!helpers.cropIsShow">
          <figure>
            <img :src="this.slide.data.image" :alt="this.slide.data.name">
          </figure>
        </div>

        <div class="banner-upload">
          <figure ref="cropContainer"></figure>
        </div>

        <div style="display:none">
          <form method="post" enctype="multipart/form-data" name="SlideForm" id="SlideForm">
            <input type="file" ref="slide"  @change="cambiarSlide($event.target.name, $event.target.files, $refs.cropContainer)">
          </form>
        </div>
        <div class="col-12 text-center spacer-bottom" v-show="!helpers.cropIsShow">
          <button type="button" class="btn-blue" name="cropImage" @click="showUpload()">Agregar imagen</button>
        </div>
        <div class="col-12 text-center" v-show="helpers.cropIsShow && !helpers.cropReady">
          <button type="button" class="btn-rose" name="cropImage" @click="cropResult()">Cortar</button>
        </div>


        <div class="row justify-content-center">
          <div class="col-6">
            <div class="okm-banner-update-form-box">
              <div class="form-row">
                <label>Nombre</label>
                <input type="text" v-model="slide.data.name">
              </div>
              <div class="form-row">
                <label>URL</label>
                <input type="text" v-model="slide.data.url">
              </div>
              <div class="col-12 text-center">
                <button type="button" class="btn-rose" name="button" @click.prevent="upLoadCrop()">Cambiar banner</button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Slide from './../../Classes/SlideClass';
import Crop from './../../Classes/Crop';
import Croppie from 'croppie';
export default {
  components : {
  },
  data(){
    return{
      slide: new Slide(),
      crop: new Crop(1200, 500,600,250, 'square'),
      maxSize: 4048,
      maxOrder: 0,
      helpers:{
        cropIsShow:false,
        cropReady:false
      }
    }
  },
  mounted(){
    this.slide.getHighestOrder();
  },
  methods:{
    showUpload(){
      this.$refs.slide.click();

    },

    cambiarSlide(name,file,cropTarget){
      this.helpers.cropIsShow = true;
      this.crop.cropImage(name,file,cropTarget);

    },

    cropResult(){
      this.slide.data.image = this.crop.cropResult();
      this.helpers.cropReady = true;
    },

    upLoadCrop(){
      let formData = new FormData(document.getElementById('SlideForm'));

      formData.append('image', this.crop.image);
      formData.append('name', this.slide.data.name);
      formData.append('url', this.slide.data.url);
      formData.append('order', this.slide.maxOrder + 1);

      this.helpers.cropIsShow = false;
      this.helpers.cropReady = false;
      this.slide.store(formData);
      //this.slide.getHighestOrder();
    }
  }


}
</script>
