export default class Comercio{

  constructor(){
    this.data = {};
    this.success = false;
    this.loading = false;
    this.error = false;
    this.errorMsg = '';
  }

  list(page,regToshow,data){
    this.loading = true;
    this._record('post','/comercioslist/'+regToshow+'?page='+page,data);
  }
  store(data){
    this.loading = true;
    this._record('post','/comercioslist/insert',data);
  }
  getLocalidades(){
    this.loading = true;
    this._record('get','/localidades',{});
  }
  getRubros(){
    this.loading = true;
    this._record('get','/rubros',{});
  }
  // show(id){
  // this._record('get','/banner/show/'+id,{});
  // }
  // update(id,data){
  //   this._record('post','/banner/update/'+id,data);
  // }
  // destroy(id){
  //   this._record('delete','/banner/'+id,{});
  // }
  //




  _record(method,url,givenData){
    axios[method](url,givenData)
    .then(response =>{this._onSuccess(response)})
    .catch(error =>{this._onError(error)});
  }

  _onSuccess(response){
    this.data = response.data;
    this.loading = false;
    return this.success = true;
  }
  _onError(error){
    this.loading = false;
    this.success = false;
    this.error = true;
    return this.errorMsg = error.response.data;
  }

}
