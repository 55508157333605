export default class ContactoClass{

  constructor(){
    this.data = {};
    this.success = false;
    this.loading = false;
  }

  list(){
    this._record('get','/contactos/',{});
  }

  show(id){
  this._record('get','/contactos/'+id,{});
  }
  update(id,data){
    this._record('patch','/contactos/update/'+id,data);
  }
  destroy(id){
    this._record('delete','/contactos/'+id,{});
  }
  create(data){

  }
  getListCsv(){
    this._record('get','/exportContactos',{});
  }

  getPlanes(){
    this.loading = true;
    this._record('get','/planes/all',{});
  }


  _record(method,url,givenData){
    axios[method](url,givenData)
    .then(response =>{this._onSuccess(response)})
    .catch(error =>{this._onError(error)});
  }

  _onSuccess(response){
    this.success = true;
    this.loading = false;
    this.data = response.data;
  }
  _onError(error){
    this.data = {error: true, errorMsg: error }
  }

}
