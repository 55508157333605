<template>
  <div>
    <!--  -->
    <figure>
      <a :href="banner.data.url">
      <img :src="banner.data.image" :alt="banner.data.name">
      </a>
    </figure>
    <!--  -->
  </div>
</template>

<script>
import Banner from './../Classes/BannerClass';
export default {
  data(){
    return{
      banner: new Banner(),
    }
  },
  mounted(){
    this.banner.show(1);
  }
}
</script>

<style lang="css" scoped>
</style>
