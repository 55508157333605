<template>
  <div>
    <!--  -->
    <div class="row justify-content-between">
      <div class="col-12 col-md-6">
        <div class="comment-new-box">
          <h3>Nuevo comentario</h3>
          <form  @submit.prevent="sendComment()" @keydown="comment.errors.clear($event.target.name)">
            <div class="form-group">
              <textarea name="comentario" rows="3" v-model="comment.comentario"></textarea>
              <input type="hidden" name="comentario_id"  v-model="comment.autor">
              <small class="error">{{comment.errors.errors}}</small>
            </div>
            <div class="form-group">
              <button type="submit" class="btn-green" name="button">Enviar</button>
            </div>
          </form>
        </div>
      </div>
      </div>
    <!--  -->
  </div>
</template>

<script>

import Form from '../Classes/FormClass';

export default {
  props:[
    'commentUserId'
  ],
  data(){
    return{
      comment: new Form({
        'comentario':'',
        'contact_id':''
      })
    }
  },
  mounted(){

  },
  watch:{
    commentUserId(){
      this.comment.contact_id = this.commentUserId;
    }
  },
  methods:{
    sendComment(){
      this.comment.post('/comentario/insert');
      this.$emit('updateData');
    }
  }
}
</script>

<style lang="css" scoped>
</style>
