<template>
  <div>
    <!--  -->
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-8">
          <h1>Nueva sucursal</h1>
        </div>

      </div>
      <div class="row justify-content-center">
        <div class="col-12 col-md-5">
          <div class="adm-sucursales-box">
            <div class="adm-sucursales-inner">

              <div class="adm-sucursales-item">
                <form method="post" name="sucursalAdd" ref="sucursalAdd" @submit.prevent="" @keydown="sucursal.errors.clear($event.target.name)">

                  <div class="form-row">
                    <label for="name">Nombre</label>
                    <input type="text" name="name" v-model="sucursal.name">
                    <small v-text="sucursal.errors.get('name')"></small>
                  </div>

                  <div class="form-row">
                    <label for="address">Dirección</label>
                    <input type="text" name="address" v-model="sucursal.address">
                    <small v-text="sucursal.errors.get('address')"></small>
                  </div>

                  <div class="form-row">
                    <label for="phone">Teléfono</label>
                    <input type="text" name="phone" v-model="sucursal.phone">
                    <small v-text="sucursal.errors.get('phone')"></small>
                  </div>

                  <div class="form-row">
                    <label for="address">Transportes</label>
                    <input type="text" name="transports" v-model="sucursal.transports">
                    <span class="input-ref">Separados por coma</span>
                    <small v-text="sucursal.errors.get('transports')"></small>
                  </div>
                  <div class="form-row">
                    <label>Transportes: resultado</label>
                    <span>{{ this.transportsComp }}</span>
                  </div>

                  <div class="form-row">
                    <button type="button" name="submi" class="btn-rose" @click.prevent="guardarSucursal()">Guardar sucursal</button>
                  </div>

                </form>


              </div>

              <div class="loading-msg" v-if="sucursal.loading == true">
                <div class="loading-inner">
                  <figure class="loading-icon">
                    <div class="lds-hourglass"></div>
                  </figure>
                  <p>Estamos pensando</p>
                </div>
              </div>
              <div class="loading-msg" v-if="sucursal.success == true">
                <div class="loading-inner">
                  <figure class="success-icon">
                    <div class="success-checkmark">
                      <div class="check-icon">
                        <span class="icon-line line-tip"></span>
                        <span class="icon-line line-long"></span>
                        <div class="icon-circle"></div>
                        <div class="icon-fix"></div>
                      </div>
                    </div>
                  </figure>
                  <p>Procesamos con éxito!</p>
                  <div class="loading-msg-cta">
                    <button @click.prevent="$router.push('/sucursales');" class="btn-rose">Listo!</button>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <!--  -->
  </div>
</template>

<script>
import Form from './../../Classes/FormClass';
import Sucursales from './../../Classes/sucursalesClass';

export default {
  data(){
    return{
      currentMidx: null,
      //optional: offset infowindow so it visually sits nicely on top of our marker
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35
        }
      },
      //Sucursal
      sucursal: new Form({
        'name':'',
        'address':'',
        'phone':'',
        'transports':'',
      }),


    }
  },
  mounted() {

 },
 computed:{
   transportsComp:{
     get: function(){
       return _.join(_.split(this.sucursal.transports,','),' / ');
     }
   }
 },
 methods: {
   // receives a place object via the autocomplete component

   guardarSucursal(){
     this.sucursal.post('/sucursales/insert');
   }
 }
}
</script>

<style lang="css" scoped>
.sucursale-map{
  height: 640px;
}
.infotooltipName{
  font-weight: bold;
}
</style>
