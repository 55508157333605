export default class Viewport {
  constructor() {

  }
  _getScrollBarWidth() {
    var inner = document.createElement('p');
    inner.style.width = "100%";
    inner.style.height = "200px";

    var outer = document.createElement('div');
    outer.style.position = "absolute";
    outer.style.top = "0px";
    outer.style.left = "0px";
    outer.style.visibility = "hidden";
    outer.style.width = "200px";
    outer.style.height = "150px";
    outer.style.overflow = "hidden";
    outer.appendChild (inner);

    document.body.appendChild (outer);
    var w1 = inner.offsetWidth;
    outer.style.overflow = 'scroll';
    var w2 = inner.offsetWidth;
    if (w1 == w2) w2 = outer.clientWidth;

    document.body.removeChild (outer);

    return (w1 - w2);
  }
  getWidth(){
    return window.innerWidth;
  }
  isMobile(){
    if (this.getWidth() <= 768) {
      return true;
    }else if (this.getWidth() > 768) {
      return false;
    }
  }
  isDesktop(){
    if (this.getWidth() <= 768) {
      return false;
    }else if (this.getWidth() > 768) {
      return true;
    }
  }
}
