<template>
  <div>
    <div class="row">
      <div class="col-12">
          <contacto :contactoId="this.id"></contacto>
      </div>
    </div>
    <br>

    <br>
    <div class="row">
      <div class="col-12">
        <comments :contactoIdComments="this.id"></comments>
      </div>
    </div>
  </div>

</template>

<script>
import moment from "moment";
import comments from '../components/comments';
import contacto from '../components/contacto';

moment.locale('es');

export default {
  components:{
    comments,
    contacto
  },
  data(){
    return{
      id:'',
      fecha:''
    }
  },
  mounted(){
    this.id =  this.$route.params.id;
  },
  methods:{
    getDateNow(date){
      this.fecha = moment(date, "YYYYMMDD").fromNow();
      return this.fecha;
    }
  }

}
</script>

<style lang="css" scoped>
</style>
