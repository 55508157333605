<template>
  <div>
    <!--  -->
    <div class="row justify-content-between">
      <div class="col-12 col-md-12 col-lg-8">
        <div class="card-contacto">
          <div class="card-header">
            <div class="contacto-name">
              {{this.contacto.data.name}} {{this.contacto.data.surName}}
            </div>

            <div class="contacto-actions">
              <div class="contacto-time">{{getDate()}}</div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12 col-md-5 card-user-divider">
                <ul>
                  <li>
                    <figure>
                      <i class="material-icons">
                        email
                      </i>
                    </figure>
                    {{ this.contacto.data.email }}
                  </li>
                  <li>
                    <figure>
                      <i class="material-icons">
                        phone_iphone
                      </i>
                    </figure>
                    {{this.contacto.data.phone}}
                  </li>
                </ul>
              </div>
              <div class="col-12 col-md-7">
                <p>{{this.contacto.data.comment}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--  -->
  </div>
</template>

<script>
import ContactoClass from '../Classes/ContactoClass';
import moment from "moment";
moment.locale('es');

export default {
  props:[
    'contactoId'
  ],
  data:function(){
    return{
      contacto : new ContactoClass(),
      usuarioId: ''
    }
  },
  beforeMount(){

  },
  mounted(){

  },
  created(){

  },
  computed:{

  },
  methods:{
    getDate(){
      return moment(this.contacto.data.created_at).fromNow();
    }
  },
  watch:{
    contactoId(val){
      this.usuarioId = this.contactoId;
    },
    usuarioId(){
      this.contacto.show(this.usuarioId)
    }
  }

}
</script>

<style lang="css" scoped>
</style>
