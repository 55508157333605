export default class Events{
  constructor(ua){
    this.ua = ua;
  }

  setEvent(section,category, action, label){
    if (typeof ga == 'function') {
      gtag('event',section, {
        'send_to': this.ua,
        'event_category': category,
        'event_action': action,
        'event_label': label,
        'event_value': 1
      });
      window.dataLayer.push({
        'event': section,
        'eventCategory': category,
        'eventMessage': label
      });
      return true;
    }else{
      return false;
    }
  }


  setVPV(title,url){
    if (typeof ga == 'function') {

      gtag('config', this.ua , {
        'page_title' : title,
        'page_path': url
      });

    }

  }

}
