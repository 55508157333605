<template>
  <!--  -->
  <div>
    <h1 class="contact-form-header">LO PEDÍS, LO TENÉS!</h1>
    <form action="/" method="post" @submit.prevent="saveSimulation()" @keydown="form.errors.clear($event.target.name)">
      <div class="simulation-disclaimer">
        <p>Simulá tu préstamo <br>y retirá efectivo sin gastos<sup>*</sup>.</p>
      </div>
      <div class="form-row">
        <div class="slider-info" v-if="this.form && this.form.monto">

          <label>Monto a pedir: <span><vue-numeric  currency="$" separator="." v-model="getAmount" read-only></vue-numeric></span></label>
        </div>
        <div class="slider-row">
          <div class="slider-box">
            <vue-slider
            v-model="form.monto"
            :min="this.loanMin"
            :max="this.loanMax"
            :marks="false"
            :interval= "500"
            dotSize="24"
            height="8px"
            :contained="false"
            tooltip="none"
            railStyle="sliderRail"
            :adsorb="false"
            :process-style="{ backgroundColor: '#F58634' }"
            >
          </vue-slider>
        </div>
      </div>
      <div class="slider-amount-marks-box">
        <div class="slider-amount-col min">
          ${{this.montoData[0]}}
        </div>
        <div class="slider-amount-col max">
          ${{this.montoData[1]}}
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="slider-info">
        <label>Plazo: <span>{{this.form.plazo}} cuotas</span></label>
      </div>
      <div class="slider-row">
        <div class="slider-box">
          <vue-slider
          v-model="form.plazo"
          :data="plazoData"
          dotSize="24"
          height="8px"
          :marks="true"
          :contained="true"
          :clickable="true"
          tooltip="none"
          railStyle="sliderRail"
          :process-style="{ backgroundColor: '#F58634' }"
          >
        </vue-slider>
      </div>
    </div>
  </div>

  <div class="slider-infor-results" v-if="this.planes.success">
    <h4>Detalle del préstamo</h4>
    <p><label>
      <i class="material-icons">
        keyboard_arrow_right
      </i>
      Monto a pedir</label>
      <vue-numeric currency="$" separator="." v-model="getAmount" read-only></vue-numeric>
    </p>
    <p><label>
      <i class="material-icons">
        keyboard_arrow_right
      </i>
      Plazo:</label>
      {{this.form.plazo}} cuotas
    </p>
    <p><label>
      <i class="material-icons">
        keyboard_arrow_right
      </i>
      Valor de la cuota promedio:</label>
      <vue-numeric currency="$" separator="." v-model="getFullAmount.cuota" read-only></vue-numeric>
    </p>
  </div>
  <div class="slider-info-disclaimer">
    <small><sup>*</sup> sujeto a disponibilidad de los planes al momento de la realización del crédito</small>
  </div>
  <div class="form-cta-row">
    <button type="submit" class="btn-primary block" name="enviar-contacto">Pedilo ahora</button>
  </div>

    </form>
  </div>
  <!--  -->
</template>

<script>
import Form from '../../Classes/FormClass';
import Contacto from '../../Classes/ContactoClass';
import Events from '../../Classes/Events';
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';
import VueNumeric from 'vue-numeric'

export default {
  components: {
    VueSlider,
    VueNumeric,
  },
  data(){
    return{
      events: new Events('UA-116927900-1'),
      montoData: ['5.000','60.000'],
      plazoData:[1,2,3,4,5,6,8,10,12],
      montoTotalADevolver:0,
      valordeCuotaADevolver:0,
      planes: new Contacto(),
      loanMin:5000,
      loanMax:60000,
      planes: new Contacto(),
      form: new Form({
        'monto':5000,
        'plazo':1,
      }),
    }
  },
  created(){
    this.planes.getPlanes();
  },
  mounted(){
    var self = this;
    window.addEventListener('unload', function(event) {
      self.events.setEvent('Simuldor','Simuldor:Abandono', 'Close window', 'Abandono');
     });
  },
  computed:{
    getFullAmount:{
      get:function(){
        if(this.planes.data && this.planes.success){
          let field =  _.find(this.planes.data,{'cuotas':this.form.plazo});
          let monto = _.replace(this.form.monto,'.','');
          return {
            'total':_.ceil(_.toNumber(field.coheficiente)*(_.toNumber(monto)*_.toInteger(this.form.plazo))),
            'cuota':_.ceil((_.toNumber(monto)*_.toNumber(field.coheficiente)))
          }
        }
      }
    },
    getAmount:{
      get:function(){
        if (this.form.monto) {
          return this.form.monto
        }
      },
      set:function(val){
        return val;
      }
    }
  },
  methods:{
    saveSimulation(){
      this.$store.commit('setMonto',this.getAmount);
      this.$store.commit('setPlazo',this.form.plazo);
      this.$store.commit('setCuotas',this.getFullAmount.cuota);
      this.events.setEvent('Simulador','Simulador:Completa simulación', 'Continúa datos personales', 'Completa simulación');
      this.events.setEvent('Simulador','Simulador:Simulación de préstamos', 'Simula monto: '+this.getAmount+ ' en: '+this.form.plazo+' cuotas', 'Simulador: monto');
      this.$router.push('/datos-personales');
    },

  }

}
</script>

<style lang="scss" scoped>
.error-msg{
    i{
      font-size: 84px;
      color: #ec6d6d;
    }
  }
</style>
