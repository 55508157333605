<template>
  <div>

    <ListadoDeContactos></ListadoDeContactos>

  </div>
</template>

<script>


import ListadoDeContactos from '../components/ListadoDeContactos.vue';

export default {
  components : {
    ListadoDeContactos
  },
  data(){
    return{}
  }
}
</script>
