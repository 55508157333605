<template>
  <div>
    <!--  -->
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="okm-banner-box" v-show="!helpers.cropIsShow">
          <figure>
            <img :src="this.banner.data.image" :alt="this.banner.data.name">
          </figure>
        </div>

        <div class="banner-upload">
          <figure ref="cropContainer"></figure>
        </div>

        <div style="display:none">
          <form method="post" enctype="multipart/form-data" name="bannerForm" id="bannerForm">
            <input type="file" ref="banner"  @change="cambiarBanner($event.target.name, $event.target.files, $refs.cropContainer)">
          </form>
        </div>
        <div class="col-12 text-center spacer-bottom" v-show="!helpers.cropIsShow">
          <button type="button" class="btn-blue" name="cropImage" @click="showUpload()">Cambiar imagen</button>
        </div>
        <div class="col-12 text-center" v-show="helpers.cropIsShow && !helpers.cropReady">
          <button type="button" class="btn-rose" name="cropImage" @click="cropResult()">Cortar</button>
        </div>


        <div class="row justify-content-center">
          <div class="col-6">
            <div class="okm-banner-update-form-box">
              <div class="form-row">
                <label for="name">Nombre</label>
                <input type="text" v-model="banner.data.name">
              </div>
              <div class="form-row">
                <label for="name">URL</label>
                <input type="text" v-model="banner.data.url">
              </div>
              <div class="col-12 text-center">
                <button type="button" class="btn-rose" name="button" @click.prevent="upLoadCrop()">Cambiar banner</button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <!--  -->
  </div>
</template>

<script>
import Banner from './../../Classes/BannerClass';
import Crop from './../../Classes/Crop';
import Croppie from 'croppie';

export default {
  data(){
    return{
      banner: new Banner(),
      crop: new Crop(1440, 400,720,200, 'square','jpg'),
      maxSize: 4048,
      helpers:{
        cropIsShow:false,
        cropReady:false
      }

    }
  },
  mounted() {
    this.banner.show(1);
  },
  watch:{
  },
  methods:{
    showUpload(){
      this.$refs.banner.click();

    },

    cambiarBanner(name,file,cropTarget){
      this.helpers.cropIsShow = true;
      this.crop.cropImage(name,file,cropTarget);

    },

    cropResult(){
      this.banner.data.image = this.crop.cropResult();
      this.helpers.cropReady = true;
    },

    upLoadCrop(){
      let formData = new FormData(document.getElementById('bannerForm'));

      formData.append('image', this.crop.image);
      formData.append('name', this.banner.data.name);
      formData.append('url', this.banner.data.url);
      this.helpers.cropIsShow = false;
      this.helpers.cropReady = false;
      this.banner.update(1,formData);
    }
  }
}
</script>

<style lang="css" scoped>
</style>
