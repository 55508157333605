<template>
  <div>
    <!--  -->
    <div class="sucursales-box">

      <div class="sucursales-list-box">
        <vuescroll :ops="ops">

            <div v-for="(sucursal, index) in sucursales.data"  :key="index" style="padding-right:14px;" >
              <a href="#" class="sucursales-item" @click.prevent="centerSucursal(sucursal.lat,sucursal.lng,15)">
                <ul>
                  <li>
                    <h4><i class="material-icons">place</i> {{sucursal.name}}</h4>
                  </li>
                  <li>{{sucursal.address}}</li>
                  <li v-if="sucursal.phone != null">TEL: {{ sucursal.phone }}</li>
                </ul>
                </a>
              </div>

          </vuescroll>
        </div>
    </div>

    <!--  -->
  </div>
</template>

<script>
import * as VueGoogleMaps from 'vue2-google-maps';
import vuescroll from 'vuescroll';
import Sucursales from './../Classes/sucursalesClass';

Vue.use(vuescroll, {
  ops: {
    // The global config
  },
  name: 'scrollpanel'
});

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyCq4D_18D-WK3CIKF0c93CZ2rXTXH2APU0",
    libraries: "places"
  }
});
export default {
  components: {
      vuescroll
    },
  data(){
    return{
      center: { lat: -34.6412584, lng: -58.6268535},
      // sucursales

      sucursales: new Sucursales(),
      markers:{},

      zoom: 10,
      //Info window
      infoContent: {
        name: '',
        address: '',
      },
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,
      //optional: offset infowindow so it visually sits nicely on top of our marker
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35
        }
      },

      //scrollPanel
      ops: {
         vuescroll: {
           mode: 'native',
           sizeStrategy: 'percent',
           detectResize: true
         },
         scrollPanel: {
           initialScrollY: 0,
           initialScrollX: false,
           scrollingX: false,
           scrollingY: true,
           speed: 300,
           easing: 'easeOutQuad',
           verticalNativeBarPos: 'right'
         },
         rail: {
           background: '#808080',
           opacity: 0.2,
           size: '6px',
           specifyBorderRadius: false,
           gutterOfEnds: null,
           gutterOfSide: '2px',
           keepShow: true
         },
         bar: {
           showDelay: 100,
           onlyShowBarOnScroll: false,
           keepShow: true,
           background: '#cecece',
           opacity: 1,
           hoverStyle: false,
           specifyBorderRadius: false,
           minSize: 0,
           size: '6px',
           disable: false
         }
       },

    }
  },

  mounted(){
    this.sucursales.list();
    this.geolocate();
  },
  computed:{

  },
  methods:{
    geolocate: function() {
      navigator.geolocation.getCurrentPosition(position => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
      });
    },
    getCoordsFromSucursales(lat,lng){
      let position = {
        lat: _.toNumber(lat),
        lng: _.toNumber(lng)
      }
      return position;
    },
    toggleInfoWindow: function(marker, idx) {
      this.infoWindowPos = this.getCoordsFromSucursales(marker.lat,marker.lng)
      this.infoContent.name = marker.name;
      this.infoContent.address = marker.address;
      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
        this.centerSucursal(marker.lat,marker.lng);
      }
    },
    centerSucursal(lat,lng,zoom){
      this.center = this.getCoordsFromSucursales(lat,lng);
      if (zoom) {
        this.zoom = zoom;
      }
    },
    getTransportsInFormat(transports){
      return _.join(_.split(transports,','),' / ');
    }
  }
}
</script>

<style lang="css" scoped>
#map{
  height: 100%;
}
.sucursales-item{
  padding-right: 6px;
}
.infotooltipName{
  font-weight: bold;
}
</style>
