<template>
    <div>
      <!-- component -->
      <div class="row">
        <div class="col-12 col-md-8">
          <h1>Contactos</h1>
        </div>
      </div>
        <div class="row">
          <div class="col-12 col-md-8">
            <div class="contacto-order-bar">
              <a href="/exportContactos" class="save-csv-cta" download target="_blank">
                <i class="material-icons">save_alt</i>
              </a>
              <div class="contacto-order-tools">
                <ul>
                  <li><a href="#" @click="orderContactBy([['created_at','read'],['desc','asc']])"><i class="material-icons">today</i></a></li>
                  <li><a href="#"  @click="orderContactBy([['read','created_at'],['asc','desc']])"><i class="material-icons">visibility</i></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-between">
            <div class="col-12 col-md-8">
              <transition-group name="list" tag="ul" >
                <li v-if="!contacts.data.error" v-for="(contacto, index) in orderedContactos" v-bind:key="contacto.id" class="card-contacto">
                  <div class="card-header">
                    <figure v-if="compareDatesWithLogin(contacto.created_at)" class="new"></figure>
                    <div class="contacto-name">
                      {{ contacto.name}} {{ contacto.surName }}
                    </div>
                    <div class="contacto-actions">
                      <div class="contacto-time">{{getDate(contacto.created_at)}}</div>
                      <div class="contacto-comments">
                        <router-link :to="{path:'/contactoDetalle/'+contacto.id}">
                          <figure>
                            <i class="material-icons">chat_bubble</i>
                          </figure>
                          <span>{{getCommentsForThisId(contacto.id)}}</span>
                        </router-link>
                      </div>
                      <button type="button" name="button" class="mark-as-read" v-bind:class="{disabled:contacto.read}" @click="markRead(contacto,contacto.id)" >
                        <i class="material-icons">
                          remove_red_eye
                        </i>
                      </button>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-12 col-md-5 card-user-divider">
                        <ul>
                          <li>
                            <figure>
                              <i class="material-icons">
                                email
                              </i>
                              Email
                            </figure>
                            {{ contacto.email }}
                          </li>
                          <li>
                            <figure>
                              <i class="material-icons">
                                phone_iphone
                              </i>
                              Teléfono
                            </figure>
                            {{ contacto.phone }}
                          </li>
                          <li>
                            <figure>
                              <i class="material-icons">
                                account_box
                              </i>
                              DNI
                            </figure>
                            {{ contacto.dni }}
                          </li>
                        </ul>
                      </div>
                      <div class="col-12 col-md-7">
                        <ul>
                          <li>
                            <figure>
                              <i class="material-icons">
                                landscape
                              </i>
                              Provincia
                            </figure>

                            {{ contacto.provincia }}
                          </li>
                          <li>
                            <figure>
                              <i class="material-icons">
                                photo_size_select_actual
                              </i>
                              Localidad
                            </figure>
                            {{ contacto.localidad }}
                          </li>
                          <li>
                            <figure>
                              <i class="material-icons">
                                receipt
                              </i>
                              Tipo de préstamo
                            </figure>
                            {{ contacto.tipoPrestamo }}
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div class="col-12">

                    </div>
                  </div>
                  <div class="card-footer text-right">
                    <button type="button" name="button" @click="deletePost(contacto.id)" ><i class="material-icons">delete</i></button>
                  </div>
                </li>
              </transition-group>
            </div>
            <div class="col-12 col-md-4">
              <ListadoDeContactosData :propMensajesSinLeer="this.mensajesSinLeer" :propMensajesNuevos="this.mensajesNuevos"></ListadoDeContactosData>
            </div>

        </div>
        <transition name="fromDown">
          <msg v-if="this.contacts.data.error" propMsgType="error">No pudimos traer los contactos. Intentalo nuevamente</msg>
        </transition>
        <!-- component -->
    </div>
</template>

<script>
import ContactoClass from '../Classes/ContactoClass';
import Comments from '../Classes/Comments';
import ListadoDeContactosData from './ListadoDeContactosData';
import msg from './msg';

import moment from "moment";
moment.locale('es');

    export default {
      components:{
        ListadoDeContactosData,
        msg
      },
        data(){
          return{
            contacts: new ContactoClass(),
            comentsCount: new Comments(),
            defaultOrder: [['created_at','read'],['desc','asc']],
            mensajesNuevos: 0,
            mensajesSinLeer: 0
          }
        },
        computed:{
          leido:{
            get: function () {
              return this.contacts.data;
            }
          },
          orderedContactos:{
            get: function(){
              return _.orderBy(this.contacts.data, this.defaultOrder[0], this.defaultOrder[1]);
            }
          }

        },
        watch:{
          'contacts.data': function(val){
            let self = this;
              this.mensajesSinLeer = _.filter(this.contacts.data,function(o){
                return !o.read
              }).length;
              this.mensajesNuevos = _.filter(this.contacts.data,function(d){
                return d.created_at >= self.$store.getters.getusrOlderLogin
              }).length;
          }

        },
        mounted() {
          this.contacts.list();
          this.comentsCount.count();

        },
        methods:{
          markRead(object,id){
            let tmpIndex = _.findIndex(this.contacts.data, ['id', id]);
            if (this.contacts.data[tmpIndex].read == '0') {
              Vue.set(object, 'read', 1);
            }else if (this.contacts.data[tmpIndex].read == '1') {
              Vue.set(object, 'read', 0);
            }
            let passData = this.contacts.data[tmpIndex];
            this.contacts.update(id,passData);

          },
          deletePost(id){
            this.contacts.destroy(id);
          },

          getDate(date){
            return moment(date).startOf('hour').fromNow();
          },
          compareDatesWithLogin(postDate){
            let usrLoginDate =  moment(this.$store.getters.getusrOlderLogin).format('YYYY-MM-DD HH:MM:SS');
            let date = moment(postDate).format('YYYY-MM-DD HH:MM:SS');

            if (usrLoginDate <= date) {
              return true;
            }else {
              return false
            }
          },
          getCommentsForThisId(id){
            if (this.comentsCount.data[id]) {
              return this.comentsCount.data[id];
            }else{
              return 0;
            }

          },
          orderContactBy(campo){
            this.defaultOrder = campo;
          }

        }
    }
</script>
<style lang="css" scoped>
.list-enter-active,
.list-leave-active,
.list-move {
  transition: 500ms cubic-bezier(0.59, 0.12, 0.34, 0.95);
  transition-property: opacity, transform;
}

.list-enter {
  opacity: 0;
}

.list-enter-to {
  opacity: 1;
}

.list-leave-active {
  position: absolute;
}

.list-leave-to {
  opacity: 0;

}
</style>
