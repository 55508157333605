<template>
  <!--  -->
  <div>
    <div class="form-card" >
    <router-view></router-view>
  </div>
  </div>
  <!--  -->
</template>

<script>
export default {
  data(){
    return{

    }
  }
}
</script>

<style lang="scss" scoped>
</style>
