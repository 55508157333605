<template>
  <div>
    <!-- template -->
      <div class="contactosDataCard">
        <div class="card-body">
          <ul>
            <li class="mensajes-nuevos">
              <figure>
                <span>{{propMensajesNuevos}}</span>
              </figure>
              <span>Mensajes nuevos</span>
            </li>
            <li class="mensajes-sin-leer">
              <figure>
                <span>
                  {{propMensajesSinLeer}}
                </span>
              </figure>
              <span>Mensajes sin leer</span>
            </li>
          </ul>
        </div>

      </div>
    <!-- template -->
  </div>
</template>

<script>
export default {

  props:[
    'propMensajesSinLeer',
    'propMensajesNuevos'
  ],
  data(){
    return{

    }
  }
}
</script>

<style lang="css" scoped>

</style>
