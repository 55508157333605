<template>
  <div>
    <!--  -->
    <div class="solicitud-background" >
      <div class="solicitud-close-row">
        <a href="#" class="solicitud-close-cta" @click.prevent="$emit('cerrarSolicitud')">
          <i class="material-icons">
            highlight_off
          </i>
        </a>
      </div>

      <section class="container" :class="formStyle">
        <div class="row justify-content-center">
          <div class="col-12 col-md-4">
            <contacto :prop-tipo-prestamo="contactoTipoPrestamo">{{formTitle}}</contacto>
          </div>
        </div>
      </section>

    </div>
    <!--  -->
  </div>
</template>

<script>

export default {
  components:{

  },
  props:[
    'formTitle',
    'formColor',
    'tipoPrestamo'
  ],
  data(){
    return{
      formStyle: this.formColor,
      contactoTipoPrestamo: this.tipoPrestamo,
    }
  },
  created(){

  },
  mounted(){

  },
  watch:{

  },
  computed:{

  },
  methods:{

  }
}
</script>

<style lang="css" scoped>
</style>
