<template>
  <div>
    <!--  -->
    <div class="row justify-content-center">
      <div class="col-4">
        <div class="col text-center">
          <div class="perfil-user-data">
            <h3>{{this.perfil.data.name}}</h3>
            <p>{{this.perfil.data.email}}</p>
          </div>
          <figure class="cropContainer">
            <!-- <img :src="this.perfil.data.avatar" ref="imageAvatar" :alt="this.perfil.data.name" v-show="!this.helpers.cropIsShow"> -->
          </figure>
          <div  class="">
            <figure ref="cropContainer"></figure>
            <button type="button" name="button" class="btn-blue" @click.prevent="cropResult()" v-show="this.helpers.cropIsShow && !this.helpers.cropReady">Cambiar</button>
            <button type="button" name="button" class="btn-blue" @click.prevent="upLoadCrop()" v-show="this.helpers.cropReady">Subir Avatar</button>
          </div>
          <div class="perfil-cta">
            <a href="#" class="btn-green" @click.prevent="showUploadAvatar()" v-show="!this.helpers.cropIsShow">Cambiar Avatar</a>
            <form method="post" enctype="multipart/form-data" name="avatarForm" id="avatarForm">
              <input type="file" ref="avatar"  @change="cambiarAvatar($event.target.name, $event.target.files,$refs.cropContainer)">
            </form>
          </div>

          <div class="perfil-password-cta-box">
            <div class="perfil-cta" v-if="!this.helpers.passwordIsShow">
              <button type="button" class="btn-rose" name="button" @click="showPasswordForm()">Cambiar password</button>
            </div>
            <form refs="usrPassword" name="passwordChange" method="post" v-if="this.helpers.passwordIsShow">
              <div class="perfil-password-change">
                <label>Nuevo password</label>
                  <div class="form-row">
                    <input type="password" name="password" v-model="passwordUno">
                  </div>
                  <div class="form-row">
                    <input type="password" name="passwordRepeat" v-model="passwordDos">
                  </div>
                  <div class="form-row">
                    <button type="button" name="cambiar-password" class="btn-rose" @click="cambiarPassword()">Cambiar password</button>
                  </div>
              </div>
            </form>
          </div>

        </div>
      </div>
    </div>
    <!--  -->
  </div>
</template>

<script>
import Perfil from '../Classes/Perfil';
import Crop from '../Classes/Crop';
import Croppie from 'croppie';

export default {
  data(){
    return{
      perfil:new Perfil(),
      newAvatar: 'avatar',
      avatarUrl: 'img/profile/avatar.png',
      crop: new Crop(120, 120,240,240, 'circle','png'),
      maxSize: 4048,
      helpers:{
        cropIsShow: false,
        cropReady:false,
        cropImage: null,
        passwordIsShow: false
      },
      passwordUno: '',
      passwordDos: '',
      }
  },
  async mounted(){
    await this.perfil.show();
  },
  watch:{

  },
  computed:{
    getAvatarState(){
      return this.$store.getters.getUptdatedAvatar;
    }
  },
  methods:{
    showUploadAvatar(){
      this.$refs.avatar.click();

    },

    cambiarAvatar(name,file,cropTarget){
      this.helpers.cropIsShow = true;
      this.crop.cropImage(name,file,cropTarget);

    },
    cropResult(){
      this.crop.cropResult();
      this.perfil.data.avatar = this.crop.image;
      this.helpers.cropReady = true;
    },

    upLoadCrop(){
      let formData = new FormData(document.getElementById('avatarForm'));
      formData.append('avatar', this.crop.image);
      this.helpers.cropIsShow = false;
      this.helpers.cropReady = false;
      this.perfil.update(formData);

    },

    showPasswordForm(){
      this.helpers.passwordIsShow = true;
    },

    cambiarPassword(){
        let formPassData = new FormData(document.getElementsByName('passwordChange'));

        if (this.passwordUno === this.passwordDos) {
          formPassData.append('password',this.passwordUno);
          this.perfil.updatePass(formPassData);
        }else{
          console.log('los pass no son iguales');
        }
    }
  }
}
</script>

<style lang="scss" scoped>
  input[type="file"]{
    display: none;
  }
  .hidden{
    display: none;
  }

</style>
