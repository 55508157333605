<template>
  <div>
    <!--  -->
    <div class="row justify-content-between">
      <div class="col-6">
        <h1>Comercios adheridos</h1>
      </div>
      <div class="col-6 text-right">

        <select class="" name="localidadesSelect" v-model="localidad" @change="changeFilter()" v-if="this.localidades.success">
          <option value="all">Localidad</option>
          <option :value="localidad.localidad" v-for="localidad in localidades.data">{{localidad.localidad}}</option>
        </select>

        <select class="" name="localidadesSelect" v-model="rubro" @change="changeFilter()" v-if="this.rubros.success">
          <option value="all">Rubro</option>
          <option :value="rubro.rubro" v-for="rubro in rubros.data">{{rubro.rubro}}</option>
        </select>

        <button type="button" name="newImport" class="btn-blue" @click.prevent="setImportWidget()">Importar</button>
      </div>
    </div>
    <div class="row justify-content-end">
      <div class="col-4">
      <div class="import-card" v-show="this.showImport">
        <div class="card-header">
          <h2>Importar Comercios</h2>
        </div>
        <div class="card-body">
        <form name="comerciosImport" ref="comerciosImport" id="comerciosImport" enctype="multipart/form-data" files="true" method="post" @submit.prevent="">
          <input type="file" ref="file" name="comercios" value="" v-on:change="getFile()">
          <div class="comercios-cta">
            <button type="button" class="btn-rose" name="button" @click.prevent="setImportWidget()">Cancelar</button>
            <button type="button" class="btn-blue" name="button" @click.prevent="sendFile()">Subir</button>
          </div>
        </form>

        </div>
      </div>
      </div>
    </div>

    <div class="row" v-if="this.comercios.success && this.comercios.data">

      <div class="col-12">

        <div class="data-table comercios header">
          <div class="data-table-item">Nombre</div>
          <div class="data-table-item">Rubro</div>
          <div class="data-table-item">Localidad</div>
          <div class="data-table-item">Dirección</div>
        </div>

        <div class="data-table comercios data-row" v-for="(comercio, index) in getComercios.data"  :key="comercio.id">
          <div class="data-table-item">{{comercio.nombre}}</div>
          <div class="data-table-item">{{comercio.rubro}}</div>
          <div class="data-table-item">{{comercio.localidad}}</div>
          <div class="data-table-item">{{comercio.domicilio}}</div>
        </div>

        <div v-if="this.comercios.success && this.comercios.data.data == ''">
          <p class="no-result">No se encontraron resultados</p>
        </div>

        <div class="row justify-content-between">
          <div class="col-8 pagination-box">
            <pagination :data="getComercios" @pagination-change-page="getResults" :limit="pageViews"></pagination>
          </div>
          <div class="col-4 pagination-box text-right">
            <label for="CantPerView">Mostrar:</label>
            <select name="CantPerView" v-model="itemsPerShow" @change="changeItemsPerView()">
              <option value="15">15</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          </div>
        </div>

      </div>



    </div>

    <transition name="fromDown">
      <msg v-if="this.import.error" propMsgType="error">{{this.import.errorMsg.comercios[0]}}</msg>
    </transition>
    <transition name="fromDown">
      <msg v-if="this.import.success" propMsgType="info">El archivo se subió bien!</msg>
    </transition>

    <div class="loading-msg full-bg" v-if="this.import.loading && !this.import.error && !this.import.success">
      <div class="loading-inner">
        <div class="lds-hourglass"></div>
        <div class="loading-msg-cta">
          Estamos subiendo tu archivo!
        </div>
      </div>
    </div>

    <!--  -->
  </div>
</template>

<script>
import Comercio from './../../Classes/ComerciosClass.js';
import pagination from 'laravel-vue-pagination';
import msg from './../../components/msg';
export default {
  components:{
    pagination,
    msg
  },
  data(){
    return{
      comercios: new Comercio(),
      rubros: new Comercio(),
      import: new Comercio(),
      files: [],
      pageViews: 5,
      itemsPerShow: 15,
      actualPage: null,
      localidad: 'all',
      rubro: 'all',
      localidades : new Comercio(),
      showImport: false,
      orderDefault:{
        order1:'localidad',
        order2:'rubro'
      },
    }
  },
  created(){
    this.comercios.list(1, this.itemsPerShow, this.setDataToSend());
    this.localidades.getLocalidades();
    this.rubros.getRubros();
    this.actualPage = 1;
  },
  computed:{
    getComercios:{
      get:function(){
        if (this.comercios.success) {
          this.actualPage = this.comercios.data.current_page;
          return this.comercios.data;
        }

      }
    }
  },
  methods:{
    sendFile(){
      let formData = new FormData(document.getElementById('comerciosImport'));
      let file = this.files;
      this.showImport = false;
      this.import.store(formData);
      this.$refs.file.value = '';
    },
    getFile(){
      this.import.error = false;
      this.files = this.$refs.file.files;
    },
    getResults(page){
      this.comercios.list(page, this.itemsPerShow, this.setDataToSend());

    },
    changeItemsPerView(){
      this.getResults(this.actualPage);
    },
    changeFilter(){
      this.getResults(1);
    },

    setImportWidget(){
      this.$refs.file.value = '';
      if (this.showImport) {
        this.showImport = false;
      }else {
        this.showImport = true;
      }
    },

    setDataToSend(){
      let filterData = new FormData();
      filterData.append('localidad', this.localidad);
      filterData.append('rubro', this.rubro);
      filterData.append('order1', this.orderDefault.order1);
      filterData.append('order2', this.orderDefault.order2);

      return filterData;
    },

  },
}
</script>

<style lang="scss" scoped>
.no-result{
  display: block;
  padding: 16px;
  text-align: center;
  font-size: 18px;
}
</style>
