export default class SlideClass{

  constructor(){
    this.data = {};
    this.dataArray = [];
    this.isUpdate = false;
    this.success = false;
    this.loading = false;
    this.complete = false;
    this.maxOrder = '';
  }

  list(){
    this.loading = true;
    this._record('get','/slide/list',{});
  }
  show(id){
    this.loading = true;
  this._record('get','/slide/show/'+id,{});
  }
  update(id,data){
    this.loading = true;
    this.isUpdate = true;
    this._record('post','/slide/update/'+id,data);
  }
  destroy(id){
    this.loading = true;
    this._record('delete','/slide/delete/'+id,{});
  }
  store(data){
    this.loading = true;
    this.isUpdate = true;
    this._record('post','/slide/insert',data);
  }
  getHighestOrder(){
    axios.get('/slide/gethighorder/',{})
    .then(response =>{
      this.maxOrder = response.data
    }
    )
    .catch(error =>{this._onError(error)});

  }

  saveOrder(data){
    this.loading = true;
    this.isUpdate = true;
    this._record('post','/slide/orderUpdate/',data);
  }




  _record(method,url,givenData){
    axios[method](url,givenData)
    .then(response =>{this._onSuccess(response)})
    .catch(error =>{this._onError(error)});
  }

  _onSuccess(response){
    this.data = response.data;
    for (var i = 0; i < response.data.length; i++) {
      this.dataArray[i] = response.data[i];
    }
    this.loading = false;
    if (this.isUpdate) {
      this.complete = true;
    }
    return this.success = true;
  }
  _onError(error){
    this.data = {error: true, errorMsg: error }
  }

}
