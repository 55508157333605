<template>
    <div>
      <!-- component -->
      <div class="row">
        <div class="col-12 col-md-8">
          <h1>Asesores</h1>
        </div>
      </div>

        <div class="row justify-content-between">
            <div class="col-12 col-md-8">
              <transition-group name="list" tag="ul" >
                <li v-if="!asesores.data.error" v-for="(asesor, index) in asesores.data" v-bind:key="asesor.id" class="card-contacto">
                  <div class="card-header">
                    <div class="contacto-name">
                      {{ asesor.nombre}} {{ asesor.apellido }}
                    </div>
                    <div class="contacto-actions">
                      <div class="contacto-time">{{getDate(asesor.created_at)}}</div>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-12 col-md-5 card-user-divider">
                        <ul>
                          <li>
                            <figure>
                              <i class="material-icons">
                                email
                              </i>
                              Email
                            </figure>
                            {{ asesor.mail }}
                          </li>
                          <li>
                            <figure>
                              <i class="material-icons">
                                phone_iphone
                              </i>
                              Teléfono
                            </figure>
                            {{ asesor.telefono }}
                          </li>
                        </ul>
                      </div>
                      <div class="col-12 col-md-7">
                        <ul>
                          <li>
                            <figure>
                              <i class="material-icons">
                                landscape
                              </i>
                              Provincia
                            </figure>

                            {{ asesor.provincia }}
                          </li>
                          <li>
                            <figure>
                              <i class="material-icons">
                                photo_size_select_actual
                              </i>
                              Localidad
                            </figure>
                            {{ asesor.localidad }}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </li>
              </transition-group>
            </div>
        </div>
        <transition name="fromDown">
          <msg v-if="this.asesores.data.error" propMsgType="error">No pudimos traer los asesores. Intentalo nuevamente</msg>
        </transition>
        <!-- component -->
    </div>
</template>

<script>
import Asesor from './../Classes/AsesoresClass';
import msg from './../components/msg';
import moment from "moment";
moment.locale('es');

export default {
  components : {
    msg
  },
  data(){
    return{
      asesores: new Asesor(),
    }
  },
  created(){
    this.asesores.list();
  },
  methods:{
    getDate(date){
      return moment(date).startOf('hour').fromNow();
    },
  },
}
</script>
