<template>
  <div>
    <!--  -->
    <header>
      <div class="container">
        <div class="row justify-content-between">
          <div class="col-6">
            <figure class="logo">
              <router-link to="/">
              <img src="/img/prestacash-logo.png" alt="Prestacash">
              </router-link>
            </figure>
          </div>
        <div class="col-6">
          <div class="social-icons">
            <ul>
              <li><a href="https://www.facebook.com/prestacash.credito" target="_blank">
                <figure><img src="/img/facebook-icon-white.svg" alt="Prestacash en Facebook"></figure>
              </a></li>
              <li><a href="https://www.instagram.com/prestacash/" target="_blank">
                <figure><img src="/img/instagram-icon-white.svg" alt="Prestacash en Instagram"></figure>
              </a></li>
              <li><a href="mailto:tk.prestacashinformes@wcentrix.net ">
                <figure><img src="/img/mail-icon-white.svg" alt="Envianos un mail"></figure>
              </a></li>
            </ul>
          </div>
        </div>

        </div>
      </div>
    </header>
      <section class="site-header container-fluid">
        <div class="row">
          <div class="col-12 col-md-12 col-lg-5 offset-lg-1 col-xl-4 offset-xl-1">
            <simulacion></simulacion>
          </div>
        </div>
      </section>

      <section class="container promociones">
        <div class="row">
          <div class="col-12">
            <div class="promociones-slider-box">
              <slidehome></slidehome>
            </div>
          </div>
        </div>
      </section>
      <div v-if="viewport">
        <section class="container sucursales">
          <div class="row">
            <div class="col-12">
              <h2>
                <div>
                  <figure>
                    <img src="/img/nuestras-sucursales-icon.svg" alt="Nuestras sucursales">
                  </figure>
                </div>
                Nuestras sucursales</h2>
            </div>
          </div>
          <sucursales></sucursales>
        </section>
      </div>


      <div v-if="!viewport">
        <div class="sucursales-mobile">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <h2>Sucursales</h2>
              </div>
              <div class="col-12">
                <router-link to="/sucursales/listado/" class="btn-blue-invert block">Ver sucursales</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>


      <comercios></comercios>

<section class="comercios">

  <div class="container-fluid">
    <div class="row">
      <div class="col col-lg-6 comercios-content-left-background">

        <div class="row justify-content-end">
          <div class="col-12 col-md-12 col-lg-10">
            <div class="comercios-box">
              <h2>Comercios</h2>
              <p>Tenemos el agrado de dirigirnos a usted para invitarlo a
                incorporarse a nuestra cartera de más de 4000 comercios adheridos en zona Norte,
                y de esa manera seguir ofreciendo una importante cantidad y calidad de productos a sus clientes</p>
                <p>A tal efecto queremos poner en su conocimiento nuestro sistema práctico,
                  fácil y seguro de trabajo donde usted podrá realizar un crédito sin moverse de su comercio.</p>
                  <p>ES IMPORTANTE DESTACAR QUE TODAS LAS OPERACIONES
                    LAS PUEDE COBRAR EN EL ACTO Y SIN DESCUENTOS EN CUALQUIERA DE NUESTRAS SUCURSALES.</p>

                    <ul>
                      <li>
                        <figure><img src="/img/financiacion-para-comercios.svg" alt="Financiación de ventas sin costo para su comercio"></figure>
                        <p>Financiación de ventas sin costo para su comercio</p>
                      </li>
                      <li>
                        <figure><img src="/img/variedad-de-planes.svg" alt="Gran variedad de planes"></figure>
                        <p>Gran variedad de planes</p>
                      </li>
                      <li>
                        <figure><img src="/img/morosidad-e-incobrabilidad.svg" alt="Morosidad e incobrabilidad a cargo de Presta Cash"></figure>
                        <p>Morosidad e incobrabilidad a cargo de Presta Cash</p>
                      </li>
                      <li>
                        <figure><img src="/img/pago-efectivo-en-el-momento.svg" alt="Pago efectivo en el momento"></figure>
                        <p>Pago efectivo en el momento</p>
                      </li>
                      <li>
                        <figure><img src="/img/atencion-rapida.svg" alt="Atención rápida y personalizada"></figure>
                        <p>Atención rápida y personalizada</p>
                      </li>
                      <li>
                        <figure><img src="/img/promociones-especiales.svg" alt="Promociones en fechas especiales"></figure>
                        <p>Promociones en fechas especiales</p>
                      </li>
                      <li>
                        <figure><img src="/img/apoyo-publicitario.svg" alt="Apoyo publicitario para incrementar sus ventas"></figure>
                        <p>Apoyo publicitario para incrementar sus ventas</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

            </div>

            <div class="col-none col-lg-6 comercios-content-right-background">

            </div>


          </div>
        </div>

      </section>



      <div class="asesores-bg">
        <section class="container asesores">
          <div class="row justify-content-around">
            <div class="col-12 col-md-4 asesor-box">
              <h3><span>Asesor comercial</span></h3>
              <a href="#" @click.prevent="showSolicitud('asesor')" class="btn-primary block">Solicitar</a>
              <div v-if="this.solicitudAsesor">
                <solicitudAsesor form-title="Solicitar Asesor" form-color="form-orange" @cerrarSolicitud="hideSolicitud()">Solicitar Asesor Comercial</solicitudAsesor>
              </div>
            </div>
            <div class="col-12 col-md-4 asesor-box">
              <h3><span>Sistema de crédito para comercios</span></h3>
              <button  class="btn-blue block" disabled="disabled">Próximamente</button>
            </div>
          </div>
        </section>
      </div>


      <requisitos></requisitos>

      <section class="container nosotros">
        <div class="row justify-content-center">
          <div class="col-12 col-md-6">
            <h2>Quienes somos</h2>
            <p>Presta Cash es una empresa dedicada a brindarle la solución directa a sus necesidades financieras. Con mínimos requerimientos usted podrá obtener dinero en efectivo sin perder tiempo con largos trámites y tediosas colas, con la mejor tasa del mercado.</p>
          </div>
        </div>
      </section>

      <div class="valoraciones-bg">
        <section class="container valoraciones">
          <div class="row justify-content-center valoraciones-header">
            <div class="col-12 col-md-6">
              <figure class="valoraciones-icon">
                <img src="/img/valoraciones-icon.svg" alt="Opiniones de nuestros clientes">
              </figure>
              <h3>Opiniones</h3>
              <figure class="valoraciones-star">
                <img src="/img/valoraciones.svg" alt="4.8">
              </figure>
              <span class="valoraciones-score">4.8</span>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-12 col-md-6">
              <div class="valoraciones-content">
                <h5>Roxana Riquelme</h5><span> - Facebook</span>
                <blockquote>
                  <p><sup>"</sup>Soy clienta es excelente la atención
                    nunca tuve problemas gracias Presta Cash<sup>"</sup>.</p>
                  </blockquote>
                </div>
                <div class="valoraciones-content">
                  <h5>Mirta Miranda</h5><span> - Facebook</span>
                  <blockquote>
                    <p><sup>"</sup>Soy clienta hace años. Y lo recomiendo xq
                      te da muchas opciones de compra y facilidad
                      de pago. Además tiene un porcentaje razonable
                      de interés. Hay otros que te prestan y te matan
                      con los intereses<sup>"</sup>.</p>
                    </blockquote>
                  </div>
                  <div class="valoraciones-content">
                    <h5>Florencia Frechero</h5><span> - Facebook</span>
                    <blockquote>
                      <p><sup>"</sup>Crédito fácil y sencillo de sacar, buena atención
                        y buenas promociones<sup>"</sup>.</p>
                      </blockquote>
                    </div>
                    <div class="valoraciones-content">
                      <h5>Carolina Colio</h5><span> - Facebook</span>
                      <blockquote>
                        <p><sup>"</sup>Hola... es muy buena la atención... nunca he tenido
                          un problema.. resulta fácil poder comprar en
                          Presta Cash... Yo soy clienta hace 12 años y todo lo
                          que tengo lo compre ahí... Yo soy de Pablo Nogues y
                          mi sucursal queda en Polvorines... muchas gracias
                          Presta Cash..<sup>"</sup>.</p>
                        </blockquote>
                      </div>
                      <div class="valoraciones-content">
                        <h5>Patty Manualidades Pividori</h5><span> - Facebook</span>
                        <blockquote>
                          <p><sup>"</sup>Desde hace más de 10 años que tengo Presta Cash y
                            jamás me trataron de mala manera. Desde el primer
                            día me sentí como en familia.
                            El trato cordial y respetuoso de sus empleados no se
                            encuentra en todas partes!! Gracias por todo !!!<sup>"</sup>.</p>
                          </blockquote>
                        </div>
                        <div class="valoraciones-content">
                          <h5>Elisabet Noemi Suleyman</h5><span> - Facebook</span>
                          <blockquote>
                            <p><sup>"</sup>La facilidad de poder comprar gracias a su servicio y
                              la excelente atención de sus empleadas<sup>"</sup>.</p>
                            </blockquote>
                          </div>
                        </div>
                      </div>
                    </section>
      </div>
      <footer>
        <div class="container">
          <div class="row">
          <div class="col-6">
            <div class="social-icons">
              <ul>
                <li><a href="https://www.facebook.com/prestacash.credito/" target="_blank">
                    <figure><img src="/img/facebook-icon-white.svg" alt="Prestacash en Facebook"></figure>
                  </a></li>
                <li><a href="https://www.instagram.com/prestacash/" target="_blank">
                    <figure><img src="/img/instagram-icon-white.svg" alt="Prestacash en Instagram"></figure>
                  </a></li>
                <li><a href="mailto:tk.prestacashinformes@wcentrix.net ">
                    <figure><img src="/img/mail-icon-white.svg" alt="Envianos un mail"></figure>
                  </a></li>
              </ul>
            </div>
          </div>
          <div class="col-6">
            <div class="okame-logo">
              <figure>
                <a href="https://www.okame.com.ar/" target="_blank">
                <img src="/img/okame-logo.svg" alt="Developed by Okame">
                </a>
              </figure>
            </div>
          </div>
        </div>
        </div>
      </footer>
      <div class="address">
        <p>CASA CENTRAL: INDEPENDENCIA AV. 3270 Piso:18 Dpto:A T:1 1225-CIUDAD AUTONOMA BUENOS AIRES</p>
      </div>

    <!--  -->
  </div>
</template>

<script>
import Viewport from './../Classes/viewport';
import solicitudPrestamo from './../siteComponents/solicitudPrestamo';
import solicitudAsesor from './../siteComponents/solicitudAsesor';
import comercios from './../siteComponents/comercioshome';
import requisitos from './../siteComponents/requisitos';
import simulacion from './../siteComponents/simuladorComp';


export default {
  components:{
    solicitudPrestamo,
    solicitudAsesor,
    comercios,
    requisitos,
    simulacion
  },
  data(){
    return{
      viewport: new Viewport(),
      solicitudPrestamoDNI: false,
      solicitudPrestamoRecibo: false,
      solicitudAsesor:false,
    }
  },
  created(){
    this.viewport = this.viewport.isDesktop();
  },
  methods:{
    showSolicitud(tipo){
      let el = document.getElementsByTagName("body");
      el[0].classList.add('open');

      if (tipo == 'dni') {
        this.solicitudPrestamoDNI = true;
      }else if (tipo == 'recibo') {
        this.solicitudPrestamoRecibo = true;
      }else if (tipo == 'asesor') {
        this.solicitudAsesor = true;
      }
    },
    hideSolicitud(){
      let el = document.getElementsByTagName("body");
      el[0].classList.remove('open');

      //openBody.classList.remove('open');
      this.solicitudPrestamoDNI = false;
      this.solicitudPrestamoRecibo = false;
      this.solicitudAsesor = false;
    }
  },
}
</script>

<style lang="css" scoped>
</style>
