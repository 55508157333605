import Errors from './FormErrorClass';

export default class Form {

  constructor(data){

    this.originalData = data;

    for(let field in data){
      this[field] = data[field];
    }

    this.errors = new Errors();

    this.success = false;
    this.loading = false;
    this.error = false;
  }

  reset(){

    for(let field in this.originalData){
      this[field] = this.originalData[field];
    }
  }

  data(){
    let data = Object.assign({},this);

    delete data.originalData;
    delete data.errors;

    return data;
  }

  post(url){
    this.loading = true;
    this.submit('post', url);
  }

  patch(){
    this.loading = true;
    this.submit('post', url);

  }


  submit(requestType, url){

    axios[requestType](url,this.data())
    .then(this._onSuccess.bind(this))
    .catch(this._onError.bind(this))
    .finally(this._onFinally());
  }


  _onSuccess(response){
    this.errors.clear();
    this.reset();
    this.loading = false;
    return this.success = true;
  }


  _onError(error){
    this.loading = false;
    return this.errors.record(error.response.data);
  }

  _onFinally(){

  }



}
