require('./bootstrap');
import Vue from 'vue';
window.Vue = Vue;

import 'es6-promise/auto';

import Vuex from 'vuex';
Vue.use(Vuex);

import VueRouter from 'vue-router';
Vue.use(VueRouter);




import axios from 'axios';
window.axios = axios;



window.axios.defaults.headers.common = {
  'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
};


Vue.component('contacto', require('./siteComponents/contacto.vue').default);
Vue.component('bannerhome', require('./siteComponents/bannerHome.vue').default);
Vue.component('slidehome', require('./siteComponents/slidehome.vue').default);
Vue.component('sucursales', require('./siteComponents/sucursales.vue').default);

var scrollToElement = require('scroll-to-element');


import router from './routes';
import createPersistedState from 'vuex-persistedstate';
const store = new Vuex.Store({
  plugins: [createPersistedState()],
  state:{
    localidad: 'all',
    rubro: 'all',
    monto: 0,
    plazo: 0,
    cuotas: 0,
  },
  getters:{
    getLocalidad: state => {
      return state.localidad;
    },
    getRubro: state => {
      return state.rubro;
    },
    getMonto: state => {
      return state.monto;
    },
    getPlazo: state => {
      return state.plazo;
    },
    getCuotas: state => {
      return state.cuotas;
    },
  },
  mutations:{
    setLocalidad(state,data){
      state.localidad = data;
    },
    setRubro(state,data){
      state.rubro = data;
    },
    setMonto(state,monto){
      state.monto = monto;
    },
    setPlazo(state,plazo){
      state.plazo = plazo;
    },
    setCuotas(state,cuotas){
      state.cuotas = cuotas;
    },
  },
});

new Vue({
   el: '#app',
   store,
   router,
   data:{

   },

});
