<template>
  <div>
    <!--  -->
    <div class="row justify-content-between">
        <div class="col-12 col-md-8">
        <div v-if="!comments.data.error" v-for="(comment, index) in orderedComments"  :key="index" class="commentCard">

          <div class="card-body">
            <div v-show="users.load" class="comment-card-user">
              <figure>
                <img :src="users.data[comment.user_id].avatar" alt="">
              </figure>
              <p>{{users.data[comment.user_id].name}}</p>
            </div>

            <div class="comment-card-content">
              <span>{{getDate(comment.created_at)}}</span>
              <p>{{comment.comentario}}</p>

            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <commentForm :commentUserId="this.commentId" @updateData="fetchData"></commentForm>
      </div>
    </div>
    <transition name="fromDown">
      <msg v-if="this.comments.data.error" propMsgType="error">No pudimos traer los comentarios. Intentalo nuevamente</msg>
    </transition>
    <!--  -->
  </div>
</template>

<script>

import Comments from '../Classes/Comments';
import Perfil from '../Classes/Perfil';
import commentForm from './commentForm';
import moment from "moment";
import msg from './msg';
moment.locale('es');

export default {
  components:{
    commentForm,
    msg
  },
  props:[
    'contactoIdComments'
  ],
  data(){
    return{
      comments: new Comments(),
      users:  new Perfil(),
      activeUser:  new Perfil(),
      commentId: '',
      loadedData: false
    }
  },
   created() {

  },
  mounted(){
    this.fetchData()
  },

  watch:{
    contactoIdComments(){
      this.comments.list(this.contactoIdComments);
      this.commentId = this.contactoIdComments;
    }

  },
  computed:{
    orderedComments: function () {
        return _.orderBy(this.comments.data, 'created_at', 'desc');
      }

  },
  methods:{
    getDate(date){
      return moment(date).fromNow();
    },
    fetchData(){
      this.users.list();
      this.activeUser.show();

      if (this.commentId != '') {
          this.comments.list(this.commentId);
      }
    }

  }
}
</script>

<style lang="css" scoped>
</style>
