<template>
  <div>
<!-- component     -->

<div class="msg" :class="this.propMsgType" v-if="!this.propMsgType == '' && this.show">
  <div class="msg-inner">
    <figure>
      <i class="material-icons" v-if="this.propMsgType == 'error'">sentiment_very_dissatisfied</i>
      <i class="material-icons" v-else-if="this.propMsgType == 'info'">sentiment_satisfied</i>
    </figure>
    <div class="msg-body">
      <h3 v-if="this.propMsgType == 'error'">¡Oops, hubo un error!</h3>
      <h3 v-else-if="this.propMsgType == 'info'">Info:</h3>
      <p><slot></slot></p>
    </div>
  </div>
</div>


<!-- component     -->
  </div>
</template>

<script>
export default {
  props:[
    'propMsgType'
  ],
  data(){
    return{
      msgType: '',
      show: 'true',
    }
  },
  mounted(){
    _.delay(()=>{
      this.show = false;
    }, 3000,)
  },

}
</script>

<style lang="css" scoped>

.msg-enter-active,
.msg-leave-active,
.msg-move {
  transition: 500ms cubic-bezier(0.59, 0.12, 0.34, 0.95);
  transition-property: opacity, transform;
}

.msg-enter {
  opacity: 0;
}

.msg-enter-to {
  opacity: 1;
}


.msg-leave-to {
  opacity: 0;

}
</style>
