import Errors from './FormErrorClass';
export default class Sucursales{

  constructor(){
    this.data = {};
    this.success = false;
    this.loading = false;
    this.errors = new Errors();
    this.error = false;
    this.complete = false;
    this.isUpdate = false;
    this.isOrderRequest = false;
    this.dataArray = [];
  }

  list(){
    this.loading = true;
    this._record('get','/sucursales/list',{});
  }
  show(id){
    this.loading = true;
    this._record('get','/sucursales/show/'+id,{});
  }
  update(id,data){
    this.loading = true;
    this.isUpdate = true;
    this._record('post','/sucursales/update/'+id,data);
  }
  destroy(id){
    this.loading = true;
    this.isUpdate = true;
    this._record('delete','/sucursales/delete/'+id,{});
  }
  store(data){
    this.loading = true;
    this.isUpdate = true;
    this._record('post','/sucursales/store/',data);
  }

  saveOrder(data){
    this.loading = true;
    this.isUpdate = true;
    this._record('post','/sucursales/orderUpdate',data);
  }
  showOrder(){
    this.loading = true;
    this.isOrderRequest = true;
    this._record('get','/sucursales/list',{});
  }




  _record(method,url,givenData){
    axios[method](url,givenData)
    .then(response =>{this._onSuccess(response)})
    .catch(error =>{this._onError(error)})
    }

  _onSuccess(response){
    this.data = response.data;
    this.loading = false;
    this.error = false;
    this.success = true;

    if (this.isUpdate) {
      this.complete = true;
    }else{
      this.complete = false;
    }
    if (this.isOrderRequest) {
      this.data = response.data;
      for (var i = 0; i < response.data.length; i++) {
        this.dataArray[i] = response.data[i];
      }
    }
    return this.success = true;
  }
  _onError(error){
    this.loading = false;
    this.error = true;
    this.data = {error: true, errorMsg: error }
    return this.errors.record(error.response.data);
  }

}
