<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-8">
        <h1>Ordenar sucursales</h1>
      </div>
    </div>
    <br>
    <br>

    <div class="row">
      <div class="col-12 col-md-6">

        <draggable v-show="!this.sucursales.complete || this.sucursales.loading" v-model="sucursalesState" :options="{animation:200, handle:'.list-handle'}" @end="orderMove()">
          <div v-for="(sucursal, index) in sucursalesState" :key="sucursal.id">
            <div class="card-contacto slide-admin-card">
              <div class="slide-order-icon-box">
                <figure class="list-handle">
                  <i class="material-icons">unfold_more</i>
                </figure>

                <figure class="slide-order-box">
                  {{ sucursal.order = sucursalesState.indexOf(sucursal)}}
                </figure>
              </div>

              <div class="card-body">
                <div class="row">
                  <div class="col-11 col-offset-1">
                    <ul class="slide-list-data">
                      <li>
                        <label>Nombre</label>
                        <span>{{sucursal.name}}</span>
                      </li>
                      <li>
                        <label>Dirección</label>
                        <span>{{sucursal.address}}</span>
                      </li>
                    </ul>

                  </div>
                </div>

              </div>


            </div>
          </div>
        </draggable>
      </div>

      <div class="col-12 col-md-4">
        <div class="slide-guardar-orden-cta">
          <a v-if="!this.sucursales.complete" href="" class="btn-rose" @click.prevent="updateOrder()">Guardar orden</a>

          <div class="loading-msg" v-if="sucursales.loading == true">
            <div class="loading-inner">
              <figure class="loading-icon">
                <div class="lds-hourglass"></div>
              </figure>
              <p>Estamos pensando</p>
            </div>
          </div>


        </div>

      </div>


    </div>

    <div class="loading-msg" v-if="sucursales.complete == true">
      <div class="loading-inner">
        <figure class="success-icon">
          <div class="success-checkmark">
            <div class="check-icon">
              <span class="icon-line line-tip"></span>
              <span class="icon-line line-long"></span>
              <div class="icon-circle"></div>
              <div class="icon-fix"></div>
            </div>
          </div>
        </figure>
        <p>Procesamos con éxito!</p>
        <div class="loading-msg-cta">
          <button @click.prevent="$router.push('/sucursales');" class="btn-rose">Listo!</button>
        </div>
      </div>
    </div>


  </div>
</template>

<script>

import Sucursales from './../../Classes/sucursalesClass';
import draggable from 'vuedraggable'

export default {
  components : {
    draggable
  },
  data(){
    return{
      sucursales : new Sucursales(),
      items: []
    }
  },
  mounted(){
    this.sucursales.showOrder();

  },
  watch:{
    'sucursales.data':function(val){
      if (val) {
        this.$store.commit('updateSucursales',  this.sucursales.data)
      }
    }
  },
  computed:{
    orderedSucursales: function () {
      return _.orderBy(this.sucursales.data, 'order', 'desc');
    },
    sucursalesState: {
       get() {
         return this.$store.state.sucursales;
       },
       set(value) {
           this.$store.commit('updateSucursales', value)
       }
   }
  },
  methods:{
    orderMove(){

    },
    updateOrder(){
      console.log(this.sucursales.dataArray);
      this.sucursales.saveOrder(this.sucursales.dataArray);
    }
  }

}
</script>
