
export default class Perfil{
  constructor(){
    this.data = {};
    this.load = false;
  }


  show(){
    this._record('get','/profile/'  ,{});
  }
  update(avatar){
    this._record('post','/profile/',avatar);
  }
  updatePass(pass){
    this._record('post','/profile/changepassword',pass);
  }

  list(){
      this._record('get','/profile/getusers',{});
  }


  _record(method,url,givenData){
    axios[method](url,givenData)
    .then(response =>{this._onSuccess(response)})
    .catch(error =>{this._onError(error)});
  }

  _onSuccess(response){
    this.data = response.data;
    this.load = true;
  }
  _onError(error){
    this.data = {error: true, errorMsg: error }
  }

}
