import { render, staticRenderFns } from "./contactoDetalle.vue?vue&type=template&id=029afaee&scoped=true&"
import script from "./contactoDetalle.vue?vue&type=script&lang=js&"
export * from "./contactoDetalle.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "029afaee",
  null
  
)

export default component.exports