<template>
  <div>
    <!--  -->
    <div class="sucursales-mobile-cointaiener">
      <div class="route-header">
        <figure class="cta">
          <router-link to="/"><i class="material-icons">keyboard_arrow_left</i> Volver</router-link>
        </figure>
        <figure class="logo">
          <img src="/img/prestacash-logo.png" alt="Prestacash">
        </figure>
      </div>
      <div class="sucursales-box router">

        <div class="sucursales-list-box">
          <vuescroll :ops="ops">

            <div v-for="(sucursal, index) in sucursales.data"  :key="index" style="padding-right:14px;" >
              <a href="#" class="sucursales-item" @click.prevent>
                <ul>
                  <li>
                    <h4><i class="material-icons">place</i> {{sucursal.name}}</h4>
                  </li>
                  <li>{{sucursal.address}}</li>
                  <li v-if="sucursal.phone != null">TEL: {{ sucursal.phone }}</li>
                  <li v-if="sucursal.transports != null"class="sucursales-transportes"><i class="material-icons">directions_bus</i>
                    <p>{{ getTransportsInFormat(sucursal.transports) }}</p></li>
                  </ul>
                </a>
              </div>

            </vuescroll>
          </div>
        </div>
    </div>
    <!--  -->
  </div>
</template>

<script>
import vuescroll from 'vuescroll';
import Sucursales from './../Classes/sucursalesClass';

export default {
  components: {
      vuescroll
    },
  data(){
    return{
      sucursales: new Sucursales(),
      //scrollPanel
      ops: {
         vuescroll: {
           mode: 'slide',
           sizeStrategy: 'percent',
           detectResize: true
         },
         scrollPanel: {
           initialScrollY: 0,
           initialScrollX: false,
           scrollingX: false,
           scrollingY: true,
           speed: 300,
           easing: 'easeOutQuad',
           verticalNativeBarPos: 'right'
         },
         rail: {
           background: '#808080',
           opacity: 0.2,
           size: '6px',
           specifyBorderRadius: false,
           gutterOfEnds: null,
           gutterOfSide: '2px',
           keepShow: true
         },
         bar: {
           showDelay: 100,
           onlyShowBarOnScroll: false,
           keepShow: true,
           background: '#cecece',
           opacity: 1,
           hoverStyle: false,
           specifyBorderRadius: false,
           minSize: 0,
           size: '6px',
           disable: false
         }
       },
    }
  },
  mounted(){
    this.sucursales.list();
  },
  methods:{
    getTransportsInFormat(transports){
      return _.join(_.split(transports,','),' / ');
    }
  },
}
</script>

<style lang="css" scoped>
</style>
